import React, { Component } from 'react';
import Select from 'react-select';
import './index.scss';

export default class ComplexSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.hide) return null;
        const { className, label, ...selectProps } = this.props;
        return (
            <div className={`w-100 position-relative ${this.props.className || ''}`}>
                {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
                <Select {...{ classNamePrefix: 'react-select', ...selectProps }} />
            </div>
        );
    }
}
