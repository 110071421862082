import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { showLoader, showMessage, handleHTTPError, putSystemSettings } from 'store/actions';
import { updateSysManage } from 'apis';
import TextBox from 'components/controls/TextBox';
import PageContentLayout from 'components/PageContentLayout';

class GeneralSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            versionNumber: 0,
            validate: false,
        };
    }

    componentDidMount = () => {
        this.setState({
            versionNumber: this.props.systemSettings.versionNumber,
        });
    };

    update = () => {
        this.setState({ validate: true });
        const { versionNumber } = this.state;

        const params = {
            _id: this.props.systemSettings._id,
            versionNumber: Number(versionNumber),
        };
        this.props.showLoader(true);
        updateSysManage(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.putSystemSettings(data.data);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Setting has been updated',
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        return (
            <PageContentLayout title="General Settings">
                <Row className="mb-4">
                    <TextBox
                        label="Version Number"
                        tabIndex="0"
                        id="versionnumber"
                        name="versionnumber"
                        type="text"
                        className="col-md-3"
                        validate={this.state.validate}
                        value={this.state.versionNumber}
                        placeholder="36"
                        onBlur={() => {}}
                        onChange={(versionNumber, e) => {
                            this.setState({ versionNumber });
                        }}
                        validationOption={{
                            type: 'number',
                            numberType: 'int',
                            check: true,
                            required: true,
                            showMsg: false,
                        }}
                    />
                </Row>
                <Row>
                    <Col md={12} className="d-flex justify-content-end">
                        <Button
                            color="secondary"
                            className="btn btn-lg mb-0"
                            style={{ width: '150px' }}
                            onClick={this.update}
                        >
                            update
                        </Button>
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser, systemSettings } = user;
    return { authUser, isLoader, systemSettings };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
        putSystemSettings,
    })(GeneralSetting)
);
