import {
    HTTP_401_ERROR,
    HTTP_OTHER_ERROR, 
} from '../actionTypes';

export const excute401ErrorHandler = (error) => {
	return {
		type: HTTP_401_ERROR,
		payload: error
	};
}

export const excuteOtherErrorHandler = (error) => {
	return {
		type: HTTP_OTHER_ERROR,
		payload: error
	};
}

export const handleHTTPError = (error) => {
    if (error.status === 401)
        return excute401ErrorHandler(error)
	else
		return excuteOtherErrorHandler(error)
}