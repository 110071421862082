import { deleteDeveloper, registerDeveloper, retrieveDevelopers } from 'apis';
import CircularProgress from 'components/CircularProgress';
import TextBox from 'components/controls/TextBox';
import PageContentLayout from 'components/PageContentLayout';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { handleHTTPError, showLoader } from 'store/actions';
import { validateEmail } from 'utils';
import swal from 'sweetalert';
import './index.scss';

class Developers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            developers: [],
            loading: false,
            email: '',
            password: '',
            validate: false,
        };
    }

    componentDidMount = () => {
        this.props.showLoader();
        retrieveDevelopers({})
            .then((data) => {
                this.setState({ developers: data.data });
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
            });
    };

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    onRegiser = () => {
        this.setState({ validate: true });
        if (!this.state.email || !this.state.password || !validateEmail(this.state.email)) return;
        const params = { email: this.state.email.toLowerCase(), password: this.state.password };
        this.setState({ loading: true });
        registerDeveloper(params)
            .then((data) => {
                this.setState({ developers: [...this.state.developers, data.data] });
                this.toggle();
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.setState({ loading: false });
            });
    };

    onDelete = async (index) => {
        const ok = await swal({
            title: 'Warning',
            text: 'Do you want to remove this Developer? \n Are you sure?',
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) return;
        const params = { _id: this.state.developers[index]._id };
        this.setState({ loading: true });
        deleteDeveloper(params)
            .then((data) => {
                const newDevelopers = [...this.state.developers];
                newDevelopers.splice(index, 1);
                this.setState({ developers: newDevelopers });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <PageContentLayout
                header={
                    <div className="d-flex justify-content-between align-items-center">
                        <h1 className="font-weight-bold mb-0">Developers</h1>
                        <Button color="primary" onClick={this.toggle}>
                            Add Developer
                        </Button>
                    </div>
                }
            >
                <table className="table table-bordered mb-0">
                    <thead className="bg-primary text-white">
                        <tr>
                            <th>No</th>
                            <th>Email</th>
                            <th>Registered Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.developers.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.email}</td>
                                <td>{moment(item.createdAt).format('MMM, DD YYYY HH:mm')}</td>
                                <td className="text-center">
                                    <Button color="danger" size="sm" onClick={() => this.onDelete(index)}>
                                        Remove
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Modal isOpen={this.state.isOpen} toggle={this.toggle} backdrop={'static'} id="add-developer-modal">
                    <ModalHeader toggle={this.cancel} className="position-relative">
                        Add New Developer
                        {this.state.loading && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '30px',
                                    height: '60px',
                                    width: '60px',
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </ModalHeader>
                    <ModalBody className="p-3">
                        <TextBox
                            label={
                                <label className="mb-0 " htmlFor="name">
                                    Email <span className="text-danger">*</span>
                                </label>
                            }
                            id="email"
                            name="email"
                            type="text"
                            className="mb-3"
                            value={this.state.email}
                            placeholder="example@mail.com"
                            validate={this.state.validate}
                            onBlur={() => {}}
                            onChange={(email, e) => {
                                this.setState({
                                    email: email.trim(),
                                });
                            }}
                            validationOption={{
                                showMsg: false,
                                name: 'Email',
                                reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                regMsg: 'Invalid Email',
                                check: true,
                                required: true,
                            }}
                        />
                        <TextBox
                            tabIndex="0"
                            id="password"
                            name="password"
                            type="password"
                            validate={this.state.validate}
                            value={this.state.password}
                            placeholder="Password"
                            onBlur={(e) => {}}
                            onChange={(password, e) => {
                                this.setState({ password });
                            }}
                            validationOption={{
                                showMsg: false,
                                name: 'Password',
                                check: true,
                                required: true,
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" size="lg" outline className="w-25" onClick={this.toggle}>
                            Cancel
                        </Button>
                        <Button color="primary" size="lg" className="w-25" onClick={this.onRegiser}>
                            Add
                        </Button>
                    </ModalFooter>
                </Modal>
            </PageContentLayout>
        );
    }
}

export default connect(null, {
    showLoader,
    handleHTTPError,
})(Developers);
