import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextArea from '../controls/TextArea';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { showMessage, handleHTTPError } from 'store/actions';
import './index.scss';

class RejectRequestRsServiceDlg extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            validate: false,
        };
    }

    cancel = () => {
        this.props.toggle();
        this.setState({
            message: '',
            validate: false,
        });
    };

    ok = () => {
        this.setState({ validate: true });
        if (!this.state.message) return;
        this.props.callback(this.state.message);
        this.cancel();
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.cancel} id="reject-requestrsserivcedlg">
                <ModalHeader toggle={this.cancel}>Message To Club</ModalHeader>
                <ModalBody className="p-3">
                    <TextArea
                        id="message"
                        name="message"
                        type="text"
                        rows={7}
                        value={this.state.message}
                        placeholder="message here"
                        validate={this.state.validate}
                        onChange={(message) => {
                            this.setState({ message });
                        }}
                        validationOption={{
                            showMsg: false,
                            check: true,
                            required: true,
                        }}
                    />
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end align-items-center">
                    <Button color="secondary" className="btn-secondary btn btn-lg" onClick={this.cancel}>
                        Cancel
                    </Button>
                    <Button color="primary" className="btn-primary btn btn-lg" onClick={this.ok}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default connect(null, {
    showMessage,
    handleHTTPError,
})(RejectRequestRsServiceDlg);
