import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { showLoader, showMessage, handleHTTPError, putSystemSettings } from 'store/actions';
import { updateSysManage } from 'apis';
import TextBox from 'components/controls/TextBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageContentLayout from 'components/PageContentLayout';

class OrganizationTagSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgTags: [],
            validate: false,
        };
        this.newTagRef = React.createRef();
    }

    componentDidMount = () => {
        let orgTags = this.props.systemSettings.orgTags || [];
        if (orgTags.length === 0) orgTags = [''];
        this.setState({ orgTags });
    };

    update = () => {
        this.setState({ validate: true });

        // check empty tags
        if (this.state.orgTags.some((tag) => !tag)) return;

        // check duplicated tags
        let dupTag = this.state.orgTags.find((tag, index, self) => self.indexOf(tag) !== index);
        if (dupTag)
            this.props.showMessage({
                show: true,
                message: { type: 'warning', text: `Duplicated tag "${dupTag}"` },
            });

        const params = {
            _id: this.props.systemSettings._id,
            orgTags: this.state.orgTags.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)),
        };
        this.props.showLoader(true);
        updateSysManage(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.putSystemSettings(data.data);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Organization Tags has been updated.',
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    add = () => {
        const orgTags = [...this.state.orgTags, ''];
        this.setState({ validate: false, orgTags });
        setTimeout(() => {
            this.newTagRef.current.scroll({ top: this.newTagRef.current.scrollHeight, behavior: 'smooth' });
        }, 300);
    };

    delete = async (index) => {
        const orgTags = [...this.state.orgTags];
        const ok = await swal({
            title: 'Warning',
            text: `Do you want to remove this tag? \n Are you sure?`,
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) return;
        orgTags.splice(index, 1);
        this.setState({ orgTags });
    };

    render() {
        const displayOrgTags = () => {
            return this.state.orgTags.map((tag, index) => {
                return (
                    <div key={index} className="mb-3 d-flex">
                        <TextBox
                            id={`${tag}`}
                            name={`${tag}`}
                            type="text"
                            className="mr-5"
                            onBlur={() => {}}
                            validate={this.state.validate}
                            value={tag || ''}
                            placeholder="ESport Club"
                            onChange={(name, e) => {
                                const orgTags = [...this.state.orgTags];
                                orgTags[index] = name;
                                this.setState({ orgTags });
                            }}
                            validationOption={{
                                required: true,
                                check: true,
                                showMsg: false,
                            }}
                        />
                        <div
                            className="text-secondary h1 d-flex justify-content-center align-items-end mb-2 pointer w-25"
                            onClick={() => {
                                this.delete(index);
                            }}
                        >
                            <FontAwesomeIcon icon="times" />
                        </div>
                    </div>
                );
            });
        };

        return (
            <PageContentLayout title="Organization Tags">
                <Row>
                    <Col md={6}>
                        <div
                            style={{ maxHeight: '500px', overflowY: 'auto' }}
                            className="border py-3 pl-3 mb-4"
                            ref={this.newTagRef}
                        >
                            {displayOrgTags()}
                        </div>
                        <Row>
                            <Col md={6}>
                                <Button color="primary" className="btn btn-lg btn-block" onClick={this.add}>
                                    <FontAwesomeIcon icon="plus" className="mr-3" />
                                    Add Tags
                                </Button>
                            </Col>
                            <Col md={6}>
                                <Button color="secondary" className="btn btn-lg btn-block" onClick={this.update}>
                                    <FontAwesomeIcon icon="save" className="mr-3" />
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser, systemSettings } = user;
    return { authUser, systemSettings };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
        putSystemSettings,
    })(OrganizationTagSetting)
);
