import { USER_SIGNOUT_SUCCESS, USER_UPDATE_SUCCESS, SET_USER_SEARCHPARAMS, PUT_SYSTEM_SETTINGS } from '../actionTypes';

import store from 'store';

const INIT_STATE = {
    authUser: store.get('user') || null,
    systemSettings: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_SIGNOUT_SUCCESS: {
            store.clearAll();
            return {
                ...state,
                authUser: null,
            };
        }

        case USER_UPDATE_SUCCESS: {
            store.set('user', action.payload);
            return {
                ...state,
                authUser: action.payload,
            };
        }

        case SET_USER_SEARCHPARAMS: {
            return {
                ...state,
                memberSearchParams: action.payload,
            };
        }

        case PUT_SYSTEM_SETTINGS: {
            return {
                ...state,
                systemSettings: action.payload,
            };
        }

        default:
            return state;
    }
};
