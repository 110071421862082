import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { showLoader, showMessage, handleHTTPError, putSystemSettings } from 'store/actions';
import { updateSysManage } from 'apis';
import TextBox from 'components/controls/TextBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AgeRanges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ageRanges: [],
            validate: false,
        };
        this.newAgeRangeRef = React.createRef();
    }

    componentDidMount = () => {
        let ageRanges = this.props.systemSettings.cmtyEventAgeRanges || [];
        if (ageRanges.length === 0) ageRanges = [{ name: '', from: '', to: '' }];
        this.setState({ ageRanges });
    };

    update = () => {
        this.setState({ validate: true });
        // valid age ranges
        if (this.state.ageRanges.some((ageRange) => !ageRange.name || !ageRange.from || !ageRange.to)) return;
        const invalidAgeRange = this.state.ageRanges.find((ageRange) => Number(ageRange.to) <= Number(ageRange.from));
        if (invalidAgeRange)
            return this.props.showMessage({
                show: true,
                message: { type: 'warning', text: `Invalid Age Range (${invalidAgeRange.name})"` },
            });
        // check duplicated even type
        let dupAgeRange = this.state.ageRanges.find(
            (type, index, self) => self.findIndex((t) => t.name.toLowerCase() === type.name.toLowerCase()) !== index
        );
        if (dupAgeRange)
            return this.props.showMessage({
                show: true,
                message: { type: 'warning', text: `Duplicated event type "${dupAgeRange.name}"` },
            });

        const params = {
            _id: this.props.systemSettings._id,
            cmtyEventAgeRanges: this.state.ageRanges.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)),
        };
        this.props.showLoader(true);
        updateSysManage(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.putSystemSettings(data.data);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Community event Age Ranges has been updated.',
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    add = () => {
        const ageRanges = [...this.state.ageRanges, { name: '', to: '', from: '' }];
        this.setState({ validate: false, ageRanges });
        setTimeout(() => {
            this.newAgeRangeRef.current.scroll({ top: this.newAgeRangeRef.current.scrollHeight, behavior: 'smooth' });
        }, 300);
    };

    delete = async (index) => {
        const ageRanges = [...this.state.ageRanges];
        const ok = await swal({
            title: 'Warning',
            text: `Do you want to remove this Age Range? \n Are you sure?`,
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) {
            return;
        }
        ageRanges.splice(index, 1);
        this.setState({ ageRanges });
    };

    render() {
        const displayAgeRanges = () => {
            return this.state.ageRanges.map((ageRange, index) => {
                return (
                    <div key={index} className="mb-3 d-flex">
                        <Row className="w-100">
                            <TextBox
                                id={`ageRangeName${index}`}
                                name={`ageRangeName${index}`}
                                type="text"
                                className="col-lg-4"
                                onBlur={() => {}}
                                validate={this.state.validate}
                                value={ageRange.name || ''}
                                placeholder="Name"
                                onChange={(name, e) => {
                                    const ageRanges = [...this.state.ageRanges];
                                    ageRanges[index].name = name;
                                    this.setState({ ageRanges });
                                }}
                                validationOption={{
                                    required: true,
                                    check: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                id={`ageRangeFrom${index}`}
                                name={`ageRangeFrom${index}`}
                                type="text"
                                className="col-lg-4"
                                onBlur={() => {}}
                                validate={this.state.validate}
                                value={ageRange.from}
                                placeholder="From"
                                onChange={(from, e) => {
                                    const ageRanges = [...this.state.ageRanges];
                                    ageRanges[index].from = Number(from);
                                    this.setState({ ageRanges });
                                }}
                                validationOption={{
                                    required: true,
                                    type: 'number',
                                    numberType: 'int',
                                    check: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                id={`ageRangeTo${index}`}
                                name={`ageRangeTo${index}`}
                                type="text"
                                className="col-lg-4"
                                onBlur={() => {}}
                                validate={this.state.validate}
                                value={ageRange.to}
                                placeholder="To"
                                onChange={(to, e) => {
                                    const ageRanges = [...this.state.ageRanges];
                                    ageRanges[index].to = Number(to);
                                    this.setState({ ageRanges });
                                }}
                                validationOption={{
                                    required: true,
                                    type: 'number',
                                    numberType: 'int',
                                    check: true,
                                    showMsg: false,
                                }}
                            />
                        </Row>
                        <div
                            className="text-secondary h1 d-flex justify-content-center align-items-end mb-2 pointer"
                            style={{ width: '50px' }}
                            onClick={() => {
                                this.delete(index);
                            }}
                        >
                            <FontAwesomeIcon icon="times" />
                        </div>
                    </div>
                );
            });
        };

        return (
            <>
                <h2 className="mb-4 font-weight-bold text-center">Community Event Age Ranges</h2>
                <div
                    style={{ maxHeight: '500px', overflowY: 'auto' }}
                    className="border py-3 pl-3 mb-4"
                    ref={this.newAgeRangeRef}
                >
                    {displayAgeRanges()}
                </div>
                <Row>
                    <Col md={6}>
                        <Button color="primary" className="btn btn-lg btn-block" onClick={this.add}>
                            <FontAwesomeIcon icon="plus" className="mr-3" />
                            Add New Age Range
                        </Button>
                    </Col>
                    <Col md={6}>
                        <Button color="secondary" className="btn btn-lg btn-block" onClick={this.update}>
                            <FontAwesomeIcon icon="save" className="mr-3" />
                            Save
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser, systemSettings } = user;
    return { authUser, systemSettings };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
        putSystemSettings,
    })(AgeRanges)
);
