import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'
import {
    showLoader,
    showMessage,
    userSignOutSuccess,
    handleHTTPError
} from 'store/actions'
import {
    getDashboardData
} from 'apis'
import './index.scss'

class Dashboard extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            dashData: null
        }
    }

    componentDidMount() {
        this.props.showLoader(true)
        getDashboardData().then(data => {
            this.props.showLoader(false);
            this.setState({
                dashData: data.data
            })
        }).catch(error => {
            this.props.showLoader(false);
            this.props.handleHTTPError(error)
        })
    }

    render() {
        const {dashData} = this.state;
        
        return (
            <div id="dashsummary" className="container" style={{minHeight: '600px'}}>
                {
                    dashData && 
                    <div className="row">
                        <div className="col-sm-6 col-12 pb-4">
                            <div className="card w-100 h-100 text-white bg-secondary p-md-5 p-4 mb-0 pointer"
                                onClick={()=>{this.props.history.push('/clubs')}}>
                                <h1 className="font-weight-bold">Clubs Management</h1>
                                <div className="row mb-3">
                                    <div className="col-sm-6 d-flex justify-content-center align-items-center"><h1 className="dash-number m-0 p-0">{dashData.venues} / {dashData.clubs}</h1></div>
                                    <div className="col-sm-6 text-center"><div className="bg-icon"><FontAwesomeIcon icon="building"/></div></div>
                                </div>
                                <h2 className="h3 text-center mb-3">(Venues / Clubs)</h2>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 pb-4">
                            <div className="card w-100 h-100 text-white bg-blue  p-md-5 p-4 mb-0 pointer"
                                onClick={()=>{this.props.history.push('/courtsreport')}}
                            >
                                <h1 className="font-weight-bold">Courts Report</h1>
                                <div className="row mb-3">
                                    <div className="col-sm-6 d-flex justify-content-center align-items-center"><h1 className="dash-number m-0 p-0">{dashData.activatedCourts} / {dashData.totalCourts}</h1></div>
                                    <div className="col-sm-6 text-center"><div className="bg-icon"><FontAwesomeIcon icon="th-large"/></div></div>
                                </div>
                                <h2 className="h3 text-center mb-3">(Activated / Total)</h2>
                            </div>
                        </div>
                        
                        <div className="col-sm-6 col-12 pb-4">
                            <div className="card w-100 h-100 text-white bg-primary p-md-5 p-4 mb-0 pointer"
                                // onClick={()=>{this.props.history.push('/court')}}
                                >
                                <h1 className="font-weight-bold">Request History</h1>
                                <div className="row mb-3">
                                    <div className="col-sm-6 d-flex justify-content-center align-items-center"><h1 className="dash-number m-0 p-0">{dashData.requests}</h1></div>
                                    <div className="col-sm-6 text-center"><div className="bg-icon"><FontAwesomeIcon icon="mail-bulk"/></div></div>
                                </div>
                                <h2 className="h3 text-center mb-3">(Total Requests Count)</h2>
                                <h2 className="text-center font-weight-bold mb-3">
                                    ({moment(this.props.authUser.createdAt).format('ddd DD.MMM YYYY')} ~ {moment().format('ddd DD.MMM YYYY')})
                                </h2> 
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 pb-4">
                            <div className="card w-100 h-100 text-white bg-teal p-md-5 p-4 mb-0 pointer"
                                // onClick={()=>{this.props.history.push('/venuelist')}}
                                >
                                <h1 className="font-weight-bold">Reservation History</h1>
                                <div className="row mb-3">
                                    <div className="col-sm-6 d-flex justify-content-center align-items-center"><h1 className="dash-number m-0 p-0">{dashData.reservations}</h1></div>
                                    <div className="col-sm-6 text-center"><div className="bg-icon"><FontAwesomeIcon icon="tasks"/></div></div>
                                </div>
                                <h2 className="h3 text-center mb-3">(Total Confirmed Reservation Count)</h2>
                                <h2 className="text-center font-weight-bold mb-3">
                                    ({moment(this.props.authUser.createdAt).format('ddd DD.MMM YYYY')} ~ {moment().format('ddd DD.MMM YYYY')})
                                </h2> 
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({user, ui}) => {
    const {isLoader} = ui;
    const {authUser} = user; 
    return {isLoader, authUser};
}

export default withRouter(connect(mapStateToProps, {
    showLoader,
    showMessage,
    userSignOutSuccess,
    handleHTTPError
})(Dashboard))
