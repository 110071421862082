import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { Button, Badge } from 'reactstrap';
import swal from 'sweetalert';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import { readInvoice, payInvoice, updateInvoice } from 'apis';
import { Constants } from 'utils/constants';
import PageContentLayout from 'components/PageContentLayout';
import UserInvoiceDetails from './UserInvoiceDetails';
import ChooseInvoicePayMethodDlg from 'components/ChooseInvoicePayMethodDlg';

class InvoiceDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoice: null,
            discount: 0,
            note: '',
            events: [],
            isOpen: false,
        };
    }

    componentDidMount = () => {
        this.searchInvoice();
    };

    searchInvoice = () => {
        const { id } = this.props.match.params;
        if (!id) this.props.history.push('/user-invoices');

        const params = { _id: id };
        this.props.showLoader(true);
        readInvoice(params)
            .then((data) => {
                this.props.showLoader(false);
                this.setState({
                    invoice: data.data,
                    discount: data.data.discount,
                    note: data.data.note,
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    payInvoiceViaStripe = async () => {
        const ok = await swal({
            title: 'Warning',
            text: 'Do you want to pay this invoice via stripe? \n Are you sure?',
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) return;
        this.props.showLoader(true);
        const { discount, note } = this.state;
        const params = {
            _id: this.state.invoice._id,
            paidServiceType: 'stripe',
            discount,
            note,
        };
        payInvoice(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({ show: true, message: { type: 'success', text: data.message } });
                this.props.history.push('/user-invoices');
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    payInvoiceViaOther = (service) => {
        this.props.showLoader(true);
        const { discount, note } = this.state;
        const params = {
            _id: this.state.invoice._id,
            paidServiceType: service,
            discount,
            note: note ?? undefined,
        };
        payInvoice(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({ show: true, message: { type: 'success', text: data.message } });
                this.props.history.push('/user-invoices');
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    rejectInvoice = async () => {
        const ok = await swal({
            title: 'Warning',
            text: 'Do you want to reject this invoice? \n Are you sure?',
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) return;
    };

    updateInvoice = () => {
        const { discount, note } = this.state;
        const params = {
            _id: this.state.invoice._id,
            discount,
            note: note ?? undefined,
        };
        this.props.showLoader(true);
        updateInvoice(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({ show: true, message: { type: 'success', text: data.message } });
                this.props.history.push('/user-invoices');
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    updateDiscount = (discount) => {
        this.setState({ discount: Number(discount) });
    };

    updateNote = (note) => {
        this.setState({ note });
    };

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    manualPayInvoice = async () => {
        if (this.state.invoice.status === Constants.INVOICE_STATUS.PENDING) {
            const ok = await swal({
                title: 'Warning',
                text: 'Payment is pending via Stripe now and this may be lead to double pay. Are you ok?',
                dangerMode: true,
                buttons: ['No', 'Yes'],
                icon: 'warning',
            });
            if (!ok) return;
        }
        this.toggle();
    };

    render() {
        if (!this.state.invoice) return null;

        return (
            <PageContentLayout
                header={
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <h1 className="font-weight-bold">
                                Invoice Details <small>(ID: {this.state.invoice._id})</small>
                            </h1>
                            <Badge
                                className="small py-1 px-3 h5 text-white ml-4"
                                pill
                                color={
                                    this.state.invoice.status === Constants.INVOICE_STATUS.PAID ? 'success' : 'warning'
                                }
                            >
                                <span className=" text-capitalize">
                                    {this.state.invoice.status === Constants.INVOICE_STATUS.CREATED
                                        ? 'Unpaid'
                                        : this.state.invoice.status}
                                </span>
                            </Badge>
                        </div>
                        {this.state.invoice.status === Constants.INVOICE_STATUS.PAID && (
                            <h3>
                                Invoice has been paid {moment(this.state.invoice.paidDate).format('DD MMM YYYY h:mm A')}
                            </h3>
                        )}
                    </div>
                }
            >
                <UserInvoiceDetails
                    invoice={this.state.invoice}
                    discount={this.state.discount}
                    note={this.state.note}
                    updateNote={this.updateNote}
                    updateDiscount={this.updateDiscount}
                />
                <div className="d-flex justify-content-end">
                    <Button
                        color="secondary"
                        outline
                        size="lg"
                        className="mr-2 px-4"
                        onClick={() => {
                            this.props.history.push('/user-invoices');
                        }}
                    >
                        Back
                    </Button>
                    {this.state.invoice.status === Constants.INVOICE_STATUS.CREATED && (
                        <>
                            <Button color="secondary" size="lg" className="mr-2 px-4" onClick={this.rejectInvoice}>
                                Reject
                            </Button>
                            <Button
                                color="primary"
                                outline
                                size="lg"
                                className="mr-2 px-4"
                                onClick={this.updateInvoice}
                            >
                                Update
                            </Button>
                            {this.state.invoice.club?.stripeId && this.state.invoice.club?.preferPaymentService && (
                                <Button
                                    color="success"
                                    size="lg"
                                    className="mr-2 px-4"
                                    onClick={this.payInvoiceViaStripe}
                                >
                                    Pay Invoice Via Stripe
                                </Button>
                            )}
                        </>
                    )}
                    {(this.state.invoice.status === Constants.INVOICE_STATUS.CREATED ||
                        this.state.invoice.status === Constants.INVOICE_STATUS.PENDING) && (
                        <Button color="primary" size="lg" className="mr-2 px-4" onClick={this.manualPayInvoice}>
                            Manual Pay
                        </Button>
                    )}
                </div>
                <ChooseInvoicePayMethodDlg
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    callback={this.payInvoiceViaOther}
                />
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser } = user;
    return { authUser };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(InvoiceDetails)
);
