import React, { Component } from 'react'
import {Button} from 'reactstrap'
import { connect } from 'react-redux';
import TextBox from 'components/controls/TextBox'
import CircularProgress from 'components/CircularProgress'
import {
    showLoader,
    showMessage,
    handleHTTPError
} from 'store/actions';
import {
    forgotPassword
} from 'apis';
import './index.scss'


class ForgotPass extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            email: "",
            validate: false,
            showMsg: false,
        }
        this.forgotPass = this.forgotPass.bind(this)
    }
    
    forgotPass(){
        this.setState({validate: true});
        if (this.state.email === ''){
            return;
        }
        this.props.showLoader(true);
        forgotPassword({
            email: this.state.email
        }).then(data => {
            this.props.showLoader(false)
            this.props.showMessage({
                show: true,
                message: {
                    text: data.message,
                    type: 'success'
                }
            })
        }).catch(error => {
            this.props.showLoader(false)
            this.props.handleHTTPError(error)
        })

    }

    render() {
        const { isLoader } = this.props;
        return (
            <div  className="forgotpass-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 offset-sm-0 content border shadow">
                            <div className="logo text-center">
                                <img src="/images/logo/logo_green.png" alt="" style={{maxWidth: '250px'}}/>
                                <h2 className="my-4 font-weight-bold">Forgot Password</h2>
                            </div>
                            <div className="form-group mb-4 text-left">
                                <label htmlFor="email">Email</label>
                                <TextBox tabIndex="0" id="email" name="email" type='text' 
                                    validate = {this.state.validate} value={this.state.email} placeholder="Email"
                                    onBlur={(e)=>{}}
                                    onChange={(email, e) => {
                                        this.setState({
                                            email: email
                                        })
                                    }}
                                    validationOption={{
                                        name: 'Email',
                                        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        regMsg: 'Invalid Email',
                                        check: true,
                                        required: true, 
                                    }}
                                />
                            </div>
                            <div className="form-group mt-5 text-center">
                                <Button color="primary" className="btn btn-primary btn-lg btn-block" onClick={this.forgotPass}>Forgot Password</Button>
                            </div>
                            <p className="text-primary linkbtn text-center mt-4" onClick={()=>{
                                this.props.history.push('/signin')
                            }}>Try login again</p>
                            {
                                isLoader &&
                                <div className="loader-view">
                                    <CircularProgress />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser } = user;
    const { isLoader} = ui;
    return { isLoader, authUser }
};

export default connect (mapStateToProps, {
    showLoader,
    showMessage,
    handleHTTPError
}) (ForgotPass);

