import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, Badge } from 'reactstrap';
import swal from 'sweetalert';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import {
    updateClubProfile,
    changeClubPassword,
    activeClub,
    blockClub,
    acceptClubRsService,
    rejectClubRsService,
    fileUpload,
} from 'apis';
import { validateEmail } from 'utils';
import TextBox from 'components/controls/TextBox';
import { Constants } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageContentLayout from 'components/PageContentLayout';
import SelectItem from 'components/controls/SelectItem';
import CheckBox from 'components/controls/CheckBox';
import timezoneList from 'utils/timezone.json';
import RejectRequestRsServiceDlg from 'components/RejectRequestRsServiceDlg';

class ClubProfileSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            mobilePhone: '',
            displayName: '',
            websiteUrl: '',
            company: '',
            facility: '',
            logoUrl: Constants.DEFAULT_LOGO,
            venueCount: 0,
            activatedCourtsCount: undefined,
            plannedCourtsCount: undefined,
            approveRsService: null,
            tempApproveRsMessage: '',
            tempApxMembersCount: 100,
            allowReservations: true,
            allowCmtyEvent: true,
            passValidate: false,
            timezone: 'America/Los_Angeles',
            isDefault: false,
            status: Constants.CLUB_STATUS.ACTIVATED,
            isOpenRejectRsService: false,
            validate: false,
        };
        this.logoRef = React.createRef();
    }

    componentDidMount = () => {
        const { club } = this.props;
        console.log(club);
        this.setState({
            email: club?.email,
            mobilePhone: club?.mobilePhone,
            displayName: club?.displayName,
            websiteUrl: club?.websiteUrl,
            company: club?.company,
            facility: club?.facility,
            logoUrl: club?.logoUrl || Constants.DEFAULT_LOGO,
            venueCount: club?.venues.length,
            plannedCourtsCount: club?.plannedCourtsCount,
            activatedCourtsCount: club?.activatedCourtsCount,
            allowReservations: club?.allowReservations,
            allowCmtyEvent: club?.allowCmtyEvent,
            timezone: club?.timezone || 'America/Los_Angeles',
            approveRsService: club?.approveRsService,
            tempApproveRsMessage: club?.tempApproveRsMessage,
            tempApxMembersCount: club?.tempApxMembersCount,
            isDefault: club.isDefault,
            status: club.status,
        });
    };

    selectLogo = () => {
        this.logoRef.current.click();
    };

    changeLogo = (e) => {
        if (e.target.files[0]) {
            this.props.showLoader(true);
            const params = {
                file: e.target.files[0],
                type: 'clubprofile',
            };
            fileUpload(params)
                .then((data) => {
                    const logoUrl = data.data;
                    updateClubProfile({
                        _id: this.props.club._id,
                        logoUrl,
                    })
                        .then(() => {
                            this.props.showLoader(false);
                            this.setState({ logoUrl });
                        })
                        .catch((error) => {
                            this.props.showLoader(false);
                            this.props.handleHTTPError(error);
                        });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    updateClub = () => {
        this.setState({ validate: true });
        const {
            email,
            displayName,
            mobilePhone,
            websiteUrl,
            company,
            facility,
            plannedCourtsCount,
            timezone,
            allowCmtyEvent,
            allowReservations,
        } = this.state;
        if (!this.state.email || !this.state.displayName || !this.state.company || !validateEmail(this.state.email))
            return;

        if (this.state.allowReservations) {
            if (!this.state.facility || !this.state.plannedCourtsCount) return;
        }

        let params = {
            _id: this.props.club._id,
            email,
            displayName,
            mobilePhone,
            websiteUrl,
            company,
            facility,
            plannedCourtsCount,
            timezone,
            allowCmtyEvent,
            allowReservations,
        };

        this.props.showLoader(true);
        updateClubProfile(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: 'Club profile updated.' },
                });
                this.goBack();
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    activeClub = async () => {
        let ok = await swal({
            title: 'Confirm',
            text: 'Do you want to active this club?\n Are you sure?',
            icon: 'info',
            buttons: true,
            dangerMode: true,
        });
        if (!ok) return;

        let params = { _id: this.props.club._id };
        this.props.showLoader(true);
        activeClub(params)
            .then((data) => {
                this.setState({ status: Constants.CLUB_STATUS.ACTIVATED });
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: `${this.state.displayName} has been activated` },
                });
                this.props.showLoader(false);
                this.goBack();
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.props.showLoader(false);
            });
    };

    blockClub = async () => {
        let ok = await swal({
            title: 'Confirm',
            text: 'Do you want to block this club?\n Are you sure?',
            icon: 'info',
            buttons: true,
            dangerMode: true,
        });
        if (!ok) return;

        let params = { _id: this.props.club._id };
        this.props.showLoader(true);
        blockClub(params)
            .then((data) => {
                this.setState({ status: Constants.CLUB_STATUS.BLOCKED });
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: `${this.state.displayName} has been blocked` },
                });
                this.props.showLoader(false);
                this.goBack();
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.props.showLoader(false);
            });
    };

    acceptRsFullService = async () => {
        let ok = await swal({
            title: 'Confirm',
            text: 'Do you want to approve full reservation service of this club?\n Are you sure?',
            icon: 'info',
            buttons: true,
            dangerMode: true,
        });
        if (!ok) return;

        let params = { _id: this.props.club._id };
        this.props.showLoader(true);
        acceptClubRsService(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: data.message },
                });
                this.goBack();
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.props.showLoader(false);
            });
    };

    rejectRsFullService = (message) => {
        let params = { _id: this.props.club._id, message };
        this.props.showLoader(true);
        rejectClubRsService(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: data.message },
                });
                this.goBack();
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.props.showLoader(false);
            });
    };

    changeClubPass = () => {
        this.setState({ passValidate: true });
        if (!this.state.newPassword || !this.state.confirmPassword) return;
        if (this.state.newPassword !== this.state.confirmPassword) {
            return this.props.showMessage({
                show: true,
                message: { type: 'error', text: "Password doesn't matched." },
            });
        }
        const params = {
            clubId: this.props.club._id,
            newPassword: this.state.newPassword,
        };
        this.props.showLoader(true);
        changeClubPassword(params)
            .then((data) => {
                this.props.showLoader(false);
                this.setState({ newPassword: '', confirmPassword: '' });
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: data.message },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    toggleRejectRsService = () => {
        this.setState({ isOpenRejectRsService: !this.state.isOpenRejectRsService });
    };

    goBack = () => {
        this.props.history.push('/clubs');
    };

    render() {
        const { club } = this.props;
        if (!club) return null;

        const getTimeZoneOptions = () => {
            return Object.keys(timezoneList).map((tz) => {
                const hr = Math.round(Math.abs(timezoneList[tz]) / 60);
                return { id: tz, name: `${tz} (GMT${timezoneList[tz] <= 0 ? '+' : '-'}${hr})` };
            });
        };

        return (
            <>
                <PageContentLayout
                    header={
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <h1 className="font-weight-bold mr-4">
                                    Club Profile <span className="h2 text-secondary">(Members: {club.users})</span>
                                </h1>
                                {this.state.status === Constants.CLUB_STATUS.INACTIVATED && (
                                    <Badge color="success" className="px-3 py-2 text-white font-weight-bold">
                                        New
                                    </Badge>
                                )}
                            </div>
                            <Button
                                className="btn btn-outline-secondary btn-lg"
                                style={{ width: '200px' }}
                                onClick={this.goBack}
                            >
                                Back
                            </Button>
                        </div>
                    }
                >
                    <div className="w-100 d-flex justify-content-center mb-5">
                        <div
                            style={{
                                width: '125px',
                                height: '125px',
                                border: '1px solid #eee',
                                background: '#efefef',
                                overflow: 'hidden',
                                borderRadius: '100%',
                                position: 'relative',
                            }}
                        >
                            <img
                                src={this.state.logoUrl || Constants.DEFAULT_LOGO}
                                alt="logo"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                id="logo"
                            />
                            <input
                                type="file"
                                name="logofile"
                                id="logofile"
                                className="d-none"
                                ref={this.logoRef}
                                onChange={this.changeLogo}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '30px',
                                    paddingTop: '5px',
                                    fontSize: '14px',
                                    color: 'white',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                }}
                                onClick={this.selectLogo}
                                className="text-center pointer"
                            >
                                <FontAwesomeIcon icon="edit" />
                            </div>
                        </div>
                    </div>
                    <Row>
                        <TextBox
                            label="ID"
                            id="ID"
                            name="ID"
                            className="col-md-3 mb-3"
                            type="text"
                            disabled={true}
                            value={this.props.club.ID}
                        />
                        <TextBox
                            label="Club Name"
                            id="displayName"
                            name="displayName"
                            className="col-md-3 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={this.state.displayName}
                            placeholder="Club Name"
                            onChange={(displayName, e) => {
                                this.setState({ displayName });
                            }}
                            validationOption={{
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                        <TextBox
                            label="Email"
                            id="email"
                            name="email"
                            className="col-md-3 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={this.state.email}
                            placeholder="Email"
                            onChange={(email, e) => {
                                this.setState({ email });
                            }}
                            validationOption={{
                                check: true,
                                required: true,
                                showMsg: false,
                                reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            }}
                        />
                        <TextBox
                            label="Mobile Phone"
                            id="mobilePhone"
                            name="mobilePhone"
                            className="col-md-3 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={this.state.mobilePhone}
                            placeholder="Mobile Phone"
                            onChange={(mobilePhone, e) => {
                                this.setState({ mobilePhone });
                            }}
                            validationOption={{
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                        <TextBox
                            label="Website Url"
                            id="websiteUrl"
                            name="websiteUrl"
                            className="col-md-3 mb-3"
                            type="text"
                            value={this.state.websiteUrl}
                            placeholder="Website Url"
                            onChange={(websiteUrl, e) => {
                                this.setState({ websiteUrl });
                            }}
                            validationOption={{
                                check: false,
                                required: false,
                                showMsg: false,
                            }}
                        />
                        <TextBox
                            label="Company"
                            id="company"
                            name="company"
                            className="col-md-3 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={this.state.company}
                            placeholder="Company Name"
                            onChange={(company, e) => {
                                this.setState({ company });
                            }}
                            validationOption={{
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                        <TextBox
                            label="Facility"
                            id="facility"
                            hide={!this.state.allowReservations}
                            name="facility"
                            className="col-md-3 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={this.state.facility}
                            placeholder="Facility"
                            onChange={(facility, e) => {
                                this.setState({ facility });
                            }}
                            validationOption={{
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                        <TextBox
                            label="Count of Venues"
                            id="venueCount"
                            name="venueCount"
                            hide={!this.state.allowReservations}
                            className="col-md-3 mb-3"
                            type="number"
                            value={this.state.venueCount}
                            placeholder="Count of Venues"
                            disabled={true}
                        />
                        <TextBox
                            label="Count of Courts Planned"
                            id="plannedCourtsCount"
                            name="plannedCourtsCount"
                            hide={!this.state.allowReservations}
                            className="col-md-3 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={this.state.plannedCourtsCount}
                            placeholder="Count of Courts Planned"
                            onChange={(plannedCourtsCount, e) => {
                                this.setState({ plannedCourtsCount });
                            }}
                            validationOption={{
                                type: 'number',
                                numbertype: 'int',
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                        <SelectItem
                            label="Time Zone"
                            id="timezone"
                            size="sm"
                            className="col-md-3 mb-3"
                            value={this.state.timezone}
                            optionList={getTimeZoneOptions()}
                            onChange={(timezone) => {
                                this.setState({
                                    timezone: timezone.id,
                                });
                            }}
                        />
                        <TextBox
                            label="Count of Courts Active"
                            id="activatedCourtsCount"
                            name="activatedCourtsCount"
                            hide={!this.state.allowReservations}
                            className="col-md-3 mb-3"
                            type="number"
                            value={this.state.activatedCourtsCount}
                            placeholder="Count of Courts Active"
                            disabled={true}
                        />
                        {!this.state.isDefault && (
                            <>
                                <Col md={3} className="mt-3">
                                    {this.state.approveRsService !== Constants.RSFULLSERVICE_APPROVE_STEP.REQUEST && (
                                        <CheckBox
                                            label="Full Service Reservations"
                                            className=""
                                            id="allowReservations"
                                            name="allowReservations"
                                            checked={this.state.allowReservations}
                                            onChange={(allowReservations) => {
                                                this.setState({ allowReservations });
                                            }}
                                        />
                                    )}
                                    <CheckBox
                                        label="Allow Community Events"
                                        className=""
                                        id="allowCmtyEvent"
                                        name="allowCmtyEvent"
                                        checked={this.state.allowCmtyEvent ?? false}
                                        onChange={(allowCmtyEvent) => {
                                            this.setState({ allowCmtyEvent });
                                        }}
                                    />
                                </Col>
                            </>
                        )}
                        <Col md={12} className="d-flex justify-content-end mt-4">
                            {(this.state.status === Constants.CLUB_STATUS.BLOCKED ||
                                this.state.status === Constants.CLUB_STATUS.INACTIVATED) && (
                                <Button
                                    color="success"
                                    className="btn btn-lg btn-block m-0"
                                    style={{ width: '150px' }}
                                    onClick={this.activeClub}
                                >
                                    Active Club
                                </Button>
                            )}
                            {this.state.status === Constants.CLUB_STATUS.ACTIVATED && (
                                <Button
                                    color="danger"
                                    className="btn btn-lg btn-block m-0"
                                    style={{ width: '150px' }}
                                    onClick={this.blockClub}
                                >
                                    Block Club
                                </Button>
                            )}
                            <Button
                                color="primary"
                                className="btn btn-lg btn-block m-0 ml-3"
                                style={{ width: '150px' }}
                                onClick={this.updateClub}
                            >
                                Save Profile
                            </Button>
                        </Col>
                    </Row>
                    {this.state.approveRsService === Constants.RSFULLSERVICE_APPROVE_STEP.REQUEST && (
                        <Row className="mt-5">
                            <Col md={12}>
                                <h2 className=" font-weight-bold text-primary">
                                    Request for full reservations service of club
                                </h2>
                            </Col>
                            <TextBox
                                label="Facility"
                                id="facility"
                                name="facility"
                                className="col-md-4 mb-3"
                                disabled
                                type="text"
                                value={this.state.facility}
                            />
                            <TextBox
                                label="Count of Courts Planned"
                                id="plannedCourtsCount"
                                name="plannedCourtsCount"
                                className="col-md-4 mb-3"
                                disabled
                                type="text"
                                value={this.state.plannedCourtsCount}
                            />
                            <TextBox
                                label="Approximated Members Count"
                                id="tempApxMembersCount"
                                name="tempApxMembersCount"
                                className="col-md-4 mb-3"
                                disabled
                                type="text"
                                value={this.state.tempApxMembersCount}
                            />
                            <Col md={12} className="mb-3">
                                <label className="font-weight-bold">Message from club</label>
                                <p>{this.state.tempApproveRsMessage}</p>
                            </Col>
                            <Col md={12} className="d-flex justify-content-end">
                                <Button
                                    color="danger"
                                    className="btn btn-lg btn-block m-0 mr-2"
                                    onClick={this.toggleRejectRsService}
                                    style={{ width: '150px' }}
                                >
                                    Reject
                                </Button>
                                <Button
                                    color="primary"
                                    className="btn btn-lg btn-block m-0"
                                    onClick={this.acceptRsFullService}
                                    style={{ width: '150px' }}
                                >
                                    Accept
                                </Button>
                            </Col>
                        </Row>
                    )}
                </PageContentLayout>
                <RejectRequestRsServiceDlg
                    isOpen={this.state.isOpenRejectRsService}
                    toggle={this.toggleRejectRsService}
                    callback={this.rejectRsFullService}
                />
            </>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser } = user;
    const { isLoader } = ui;
    return { authUser, isLoader };
};
export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(ClubProfileSetting)
);
