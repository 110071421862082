export const Constants = {
    VERSION_NUMBER: 100,

    DEMOCLUB_ID: '00002',

    DEFAULT_LOGO: 'https://tennislives.s3.amazonaws.com/logo.png',

    COURT_COLORS: ['#dc3545', '#3281ff', '#f9c10a', '#a332ff', '#a02b6e', '#2ba09e'],

    CLUB_STATUS: {
        INACTIVATED: 0,
        ACTIVATED: 1,
        BLOCKED: 2,
    },

    CLUB_TYPE: {
        REAL: 'real',
        VIRTUAL: 'virtual',
    },

    GENDER: {
        MALE: 0,
        FEMALE: 1,
        NOPREFER: 2,
    },

    POST_STATUS: {
        PENDING: 0,
        ACCEPTED: 1,
    },

    POST_PUB_STATUS: {
        PRIVATE: 0,
        PUBLIC: 1,
    },

    COMMENT_STATUS: {
        DRAFT: 0,
        ADDED: 1,
    },

    COMMENT_USERTYPE: {
        SPONSOR: 0,
        CLUB: 1,
        SUPERADMIN: 2,
    },

    avtTimeSlotLabels: [
        {
            name: 'Day Time',
            stTime: '06:00 AM',
            etTime: '11:00 PM',
        },
        {
            name: 'Evening',
            stTime: '05:45 PM',
            etTime: '11:00 PM',
        },
        {
            name: 'Morning',
            stTime: '06:00 AM',
            etTime: '12:45 PM',
        },
        {
            name: 'Midday',
            stTime: '12:00 PM',
            etTime: '02:00 PM',
        },
        {
            name: 'Afternoon',
            stTime: '12:30 PM',
            etTime: '06:30 PM',
        },
        {
            name: 'Early Morning',
            stTime: '06:00 AM',
            etTime: '09:30 AM',
        },
        {
            name: 'Mid Morning',
            stTime: '08:30 AM',
            etTime: '10:45 AM',
        },
        {
            name: 'Late Morning',
            stTime: '10:00 AM',
            etTime: '12:45 PM',
        },
        {
            name: 'Early Afternoon',
            stTime: '01:30 PM',
            etTime: '03:00 PM',
        },
        {
            name: 'Mid Afternoon',
            stTime: '02:30 PM',
            etTime: '04:00 PM',
        },
        {
            name: 'Late Afternoon',
            stTime: '04:00 PM',
            etTime: '06:30 PM',
        },
        {
            name: 'Early Evening',
            stTime: '05:30 PM',
            etTime: '07:30 PM',
        },
        {
            name: 'Late Evening',
            stTime: '08:30 PM',
            etTime: '11:00 PM',
        },
    ],

    RSFULLSERVICE_APPROVE_STEP: {
        NO_REQUEST: 'no_request',
        REQUEST: 'request',
        APPROVED: 'approved',
    },
    LEVEL_TYPES: [
        {
            id: '0',
            name: 'UTR',
        },
        {
            id: '1',
            name: 'USTA',
        },
        {
            id: '2',
            name: 'SELF',
        },
        {
            id: '3',
            name: 'PRO',
        },
    ],

    CMTYEVENT_STATUS: {
        PUBLISHED: 'published',
        FINISHED: 'finished',
        CANCELED: 'canceled',
    },

    CMTYEVENT_REGUSER_STATUS: {
        REGISTERED: 'registered',
        CONFIRMED: 'confirmed',
        CANCELED: 'canceled',
    },

    INVOICE_STATUS: {
        DRAFT: 'draft',
        CREATED: 'created',
        PENDING: 'pending',
        PAID: 'paid',
        CANCELED: 'canceled',
    },
};
