import React, { Component } from 'react';
import { Provider } from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import configureStore from './store';
import Container from './containers'
import ErrorBoundary from './errorBoundary'
import 'rc-drawer/assets/index.css';
import './sass/index.scss';


library.add(fas);
export const store = configureStore ();

class App extends Component {

    render() {

        return (
            <div className="app">
                <Provider store={store}>
                    <Router onUpdate={() => window.scrollTo(0, 0)}>
						<ErrorBoundary>
                            <Route path="/" component={Container} />
						</ErrorBoundary>
					</Router>
                </Provider>
            </div>
        );
    }
}

export default App;
