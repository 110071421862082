import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import {
    showLoader,
    showMessage,
    userUpdateSuccess,
    userSignOutSuccess,
    handleHTTPError
} from 'store/actions';
import {
    signIn
} from 'apis';
import { validateEmail} from 'utils';
import CircularProgress from 'components/CircularProgress'
import TextBox from 'components/controls/TextBox'
import './index.scss'

class SignIn extends React.Component {
    constructor () {
        super ();
        this.state = {
            showMsg: false,
            email: '',
            password: '',
            validate: false,
        }
        this.signInAction = this.signInAction.bind(this)
    }

    componentDidMount(){
        this.props.userSignOutSuccess();
    }

    signInAction(){
        this.setState({validate: true});
        if (this.state.email === "" || !validateEmail(this.state.email)){
            return;
        }
        if(this.state.password === ""){
            return;
        }
        const params = {
            email: this.state.email,
            password: this.state.password,
        }
        this.props.showLoader(true);
        signIn(params).then(data => {
            this.props.showLoader(false);
            this.props.userUpdateSuccess(data.data);
            this.props.history.push('/dashboard')
        }).catch(error => {
            this.props.showLoader(false);
            this.props.handleHTTPError(error)
        })
        
    }

    render () {
        const {
            email,
            password
        } = this.state;
        const { isLoader } = this.props;
        return (
            <div  className="app-login-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 offset-sm-0 content border shadow">
                            <div className="logo text-center">
                                <img src="/images/logo/logo_green.png" alt=""/>
                                <h2 className="mt-4 font-weight-bold">(Super Administrator)</h2>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="email">Email</label>
                                <TextBox tabIndex="0" id="email" name="email" type='text' 
                                    validate = {this.state.validate} value={email} placeholder="Email"
                                    onBlur={(e)=>{}}
                                    onChange={(email, e) => {
                                        this.setState({
                                            email: email
                                        })
                                    }}
                                    validationOption={{
                                        showMsg: false,
                                        name: 'Email',
                                        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        regMsg: 'Invalid Email',
                                        check: true,
                                        required: true, 
                                    }}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="email">Password</label>
                                <TextBox tabIndex="0" id="password" name="password" type='password' 
                                    validate = {this.state.validate} value={password} placeholder="Password"
                                    onBlur={(e)=>{}}
                                    
                                    onChange={(password, e) => {
                                        this.setState({
                                            password: password
                                        })
                                    }}
                                    validationOption={{
                                        showMsg: false,
                                        name: 'Password',
                                        check: true,
                                        required: true, 
                                    }}
                                />
                            </div>
                            <div className="form-group mt-4">
                                <Button color="primary" className="btn-primary btn btn-block btn-lg" onClick={this.signInAction}>Login</Button>
                            </div>
                            <div className="text-center mt-3">
                                <Link to="/forgotpass">Forgot your password?</Link>
                            </div>
                            {
                                isLoader &&
                                <div className="loader-view">
                                    <CircularProgress />
                                </div>
                            }
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser } = user;
    const { isLoader} = ui;
    return { isLoader, authUser }
};

export default connect (mapStateToProps, {
    showLoader,
    showMessage,
    userUpdateSuccess,
    userSignOutSuccess,
    handleHTTPError
}) (SignIn);
