import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PageContentLayout from 'components/PageContentLayout';
import ZipCodes from './ZipCodes';
import EventTypes from './EventTypes';
import VenueSettings from './VenueSettings';
import AgeRanges from './AgeRanges';

class CmtyEventSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {};

    render() {
        return (
            <PageContentLayout title="Community Event Settings">
                <Row>
                    <Col md={6} className="mb-5">
                        <ZipCodes />
                    </Col>
                    <Col md={6} className="mb-5">
                        <EventTypes />
                    </Col>
                    <Col md={6} className="mb-5">
                        <VenueSettings />
                    </Col>
                    <Col md={6} className="mb-5">
                        <AgeRanges />
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

export default CmtyEventSetting;
