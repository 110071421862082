import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import InvoiceList from './list';
import InvoiceDetails from './details';

class ClubInvoices extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={`${this.props.match.url}`} component={InvoiceList} />
                <Route exact path={`${this.props.match.url}/:id`} component={InvoiceDetails} />
            </Switch>
        );
    }
}

export default ClubInvoices;
