import React from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    userSignOutSuccess
} from 'store/actions'

class UserInfo extends React.Component {
    render() {

        return (
            <div>
                <div className="user-profile">
                    <div className="user-detail ml-2">
                        <h6 className="user-name mb-0">{this.props.authUser.displayName}</h6>
                        <p className="mb-0 font-weight-bold">{this.props.authUser.email}</p>
                        <small>Super Admin</small>
                    </div>
                </div>
                <a className="dropdown-item text-muted" href="null" onClick={(e) => {
                    e.preventDefault()
                    this.props.history.push('/profile');
                }}> <FontAwesomeIcon icon="user"/> &nbsp;&nbsp;&nbsp;Profile
                </a>
                <a className="dropdown-item text-muted" href="null" onClick={(e) => {
                    e.preventDefault()
                    this.props.userSignOutSuccess();
                    this.props.history.push('/signin');
                    this.props.toggle();
                }}>
                    <FontAwesomeIcon icon="sign-out-alt" /> &nbsp;&nbsp;&nbsp;Logout
                </a>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser } = user;
    return { authUser };
}

export default connect(mapStateToProps, {
    userSignOutSuccess
})(UserInfo);


