import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextBox from '../controls/TextBox';
import CheckBox from '../controls/CheckBox';
import CircularProgress from '../CircularProgress';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { validateEmail } from 'utils';
import { showMessage, handleHTTPError } from 'store/actions';
import timezoneList from 'utils/timezone.json';
import { createClub } from 'apis';
import ComplexSelect from 'components/controls/ComplexSelect';
import './index.scss';
import SelectItem from 'components/controls/SelectItem';
import { Constants } from 'utils/constants';

class CreateClubDlg extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayName: '',
            email: '',
            mobilePhone: '',
            company: '',
            facility: '',
            password: '',
            confirmPass: '',
            plannedCourtsCount: '',
            allowReservations: true,
            allowCmtyEvent: true,
            allowUserSignUp: false,
            timezone: 'America/Los_Angeles',
            type: Constants.CLUB_TYPE.REAL,
            isDefault: false,
            showDefaultOption: false,
            isPassword: false,
            orgTags: [],
            validate: false,
            loading: false,
        };
    }

    create = () => {
        if (this.state.loading) return;
        this.setState({ validate: true });

        if (
            !this.state.displayName ||
            !this.state.email ||
            !validateEmail(this.state.email) ||
            !this.state.company ||
            !this.state.mobilePhone
        ) {
            return;
        }
        if (
            this.state.allowReservations &&
            this.state.type === Constants.CLUB_TYPE.REAL &&
            (!this.state.facility || !this.state.plannedCourtsCount)
        )
            return;
        if (this.state.isPassword && (!this.state.password || !this.state.confirmPass)) return;

        if (this.state.isPassword && this.state.password !== this.state.confirmPass) {
            this.setState({ confirmPass: '' });
            return this.props.showMessage({
                show: true,
                message: { text: "Password doesn't be matched", type: 'error' },
            });
        }

        if (!this.state.allowReservations && !this.state.allowCmtyEvent) {
            return this.props.showMessage({
                show: true,
                message: { text: 'Please allow at least one of two feature', type: 'warning' },
            });
        }

        let params = {
            displayName: this.state.displayName,
            email: this.state.email,
            mobilePhone: this.state.mobilePhone,
            company: this.state.company,
            allowReservations: this.state.allowReservations,
            allowCmtyEvent: this.state.allowCmtyEvent,
            allowUserSignUp: this.state.allowUserSignUp,
            isDefault: this.state.showDefaultOption ? this.state.isDefault : undefined,
            timezone: this.state.timezone,
            type: this.state.type,
            orgTags: this.state.orgTags,
        };
        if (this.state.allowReservations) {
            params = {
                ...params,
                facility: this.state.facility,
                plannedCourtsCount: Number(this.state.plannedCourtsCount),
            };
        }
        if (this.state.isPassword) {
            params = {
                ...params,
                password: this.state.password,
            };
        }
        this.setState({ loading: true });
        createClub(params)
            .then((data) => {
                this.setState({ loading: false });
                this.props.updateClubs();
                this.cancel();
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.props.handleHTTPError(error);
            });
    };

    cancel = () => {
        this.props.toggle();
        this.setState({
            displayName: '',
            email: '',
            mobilePhone: '',
            company: '',
            facility: '',
            password: '',
            confirmPass: '',
            plannedCourtsCount: '',
            allowReservations: true,
            allowCmtyEvent: true,
            allowUserSignUp: false,
            timezone: 'America/Los_Angeles',
            type: Constants.CLUB_TYPE.REAL,
            isDefault: false,
            showDefaultOption: false,
            orgTags: [],
            isPassword: false,
            validate: false,
            loading: false,
        });
    };

    componentDidUpdate = (prevProps) => {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({ showDefaultOption: this.props.showDefaultOption });
        }
    };

    render() {
        const { displayName, email, mobilePhone, company, facility, password, confirmPass, plannedCourtsCount } =
            this.state;

        const getTimeZoneOptions = () => {
            return Object.keys(timezoneList).map((tz) => {
                const hr = Math.round(timezoneList[tz] / 60);
                return { id: tz, name: `${tz} (GMT${hr === 0 ? '' : hr > 0 ? '+' : '-'}${hr})` };
            });
        };

        const getClubTypeOptions = () => {
            return Object.values(Constants.CLUB_TYPE).map((x) => {
                return { id: x, name: x.toUpperCase() };
            });
        };

        const getClubTags = () => {
            const iligibleTags = (this.state.orgTags || []).filter((tag) =>
                this.props.systemSettings.orgTags.includes(tag)
            );
            return iligibleTags.map((tag) => {
                return { value: tag, label: tag };
            });
        };

        const generateOrgTagsOption = () => {
            return this.props.systemSettings.orgTags.map((tag) => {
                return { value: tag, label: tag };
            });
        };

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={this.props.className}
                id="createclub"
            >
                <ModalHeader toggle={this.props.toggle}>
                    {' '}
                    Create New Club
                    {this.state.loading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '2px',
                                right: '60px',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                </ModalHeader>
                <ModalBody className=" position-relative" style={{ zIndex: '1000' }}>
                    <Row>
                        <TextBox
                            label={
                                <label htmlFor="displayName" className="mb-0">
                                    <span>
                                        Display Name{' '}
                                        <small className=" font-weight-bold">
                                            &nbsp;(Ex: Mt.Tam Racquet Club etc..)
                                        </small>
                                    </span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            id="displayName"
                            name="displayName"
                            className="col-md-6 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={displayName}
                            placeholder="Mt.Tam Racquet Club"
                            onBlur={(e) => {}}
                            onChange={(displayName, e) => {
                                this.setState({ displayName });
                            }}
                            validationOption={{
                                showMsg: false,
                                check: true,
                                required: true,
                            }}
                        />
                        <TextBox
                            label={
                                <label htmlFor="email" className="mb-0">
                                    <span>Email</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            id="email"
                            name="email"
                            className="col-md-6 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={email}
                            placeholder="club@mail.com"
                            onBlur={(e) => {}}
                            onChange={(email, e) => {
                                this.setState({ email });
                            }}
                            validationOption={{
                                showMsg: false,
                                reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                check: true,
                                required: true,
                            }}
                        />
                        <TextBox
                            label={
                                <label htmlFor="company" className="mb-0">
                                    <span>Company</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            id="company"
                            name="company"
                            className="col-md-6 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={company}
                            placeholder="Mt.Tam Racquet Club"
                            onBlur={(e) => {}}
                            onChange={(company, e) => {
                                this.setState({ company });
                            }}
                            validationOption={{
                                showMsg: false,
                                check: true,
                                required: true,
                            }}
                        />
                        <TextBox
                            label={
                                <label htmlFor="mobilePhone" className="mb-0">
                                    <span>Mobile Phone</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            id="mobilePhone"
                            name="mobilePhone"
                            className="col-md-6 mb-3"
                            type="text"
                            validate={this.state.validate}
                            value={mobilePhone}
                            placeholder="4589762123"
                            onBlur={(e) => {}}
                            onChange={(mobilePhone, e) => {
                                this.setState({ mobilePhone });
                            }}
                            validationOption={{
                                showMsg: false,
                                check: true,
                                required: true,
                            }}
                        />
                        <SelectItem
                            label={
                                <label htmlFor="timezone" className="mb-0">
                                    <span>Time Zone</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            className="col-md-6 mb-3"
                            id="timezone"
                            size="sm"
                            value={this.state.timezone}
                            optionList={getTimeZoneOptions()}
                            onChange={(timezone) => {
                                this.setState({
                                    timezone: timezone.id,
                                });
                            }}
                        />
                        <SelectItem
                            label={
                                <label htmlFor="type" className="mb-0">
                                    <span>Type</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            className="col-md-6 mb-3"
                            id="type"
                            size="sm"
                            value={this.state.type}
                            optionList={getClubTypeOptions()}
                            onChange={(type) => {
                                this.setState({
                                    type: type.id,
                                    allowReservations:
                                        type.id === Constants.CLUB_TYPE.VIRTUAL ? false : this.state.allowReservations,
                                });
                            }}
                        />
                        <Col md={4} className="mb-3 text-center">
                            <CheckBox
                                label="Full Reservation Service"
                                id="allowReservations"
                                name="allowReservations"
                                disabled={this.state.type === Constants.CLUB_TYPE.VIRTUAL}
                                checked={this.state.allowReservations}
                                onChange={(allowReservations) => this.setState({ allowReservations })}
                            />
                        </Col>
                        <Col md={4} className="mb-3 text-center">
                            <CheckBox
                                label="Allow Community Events"
                                id="allowCmtyEvent"
                                name="allowCmtyEvent"
                                checked={this.state.allowCmtyEvent}
                                onChange={(allowCmtyEvent) => this.setState({ allowCmtyEvent })}
                            />
                        </Col>
                        <Col md={4} className="mb-3 text-center">
                            <CheckBox
                                label="Allow User Signup"
                                id="allowUserSignUp"
                                name="allowUserSignUp"
                                checked={this.state.allowUserSignUp}
                                onChange={(allowUserSignUp) => this.setState({ allowUserSignUp })}
                            />
                        </Col>
                        <TextBox
                            label={
                                <label htmlFor="facility" className="mb-0">
                                    <span>Facility</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            id="facility"
                            name="facility"
                            type="text"
                            hide={!this.state.allowReservations || this.state.type === Constants.CLUB_TYPE.VIRTUAL}
                            className="col-md-6 mb-3"
                            validate={this.state.validate}
                            value={facility}
                            placeholder="Facility"
                            onBlur={(e) => {}}
                            onChange={(facility, e) => {
                                this.setState({ facility });
                            }}
                            validationOption={{
                                showMsg: false,
                                check: true,
                                required: true,
                            }}
                        />
                        <TextBox
                            label={
                                <label htmlFor="plannedCourtsCount" className="mb-0">
                                    <span>Count of Court Planned</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            id="plannedCourtsCount"
                            name="plannedCourtsCount"
                            type="text"
                            hide={!this.state.allowReservations || this.state.type === Constants.CLUB_TYPE.VIRTUAL}
                            className="col-md-6 mb-3"
                            validate={this.state.validate}
                            value={plannedCourtsCount}
                            placeholder="plannedCourtsCount"
                            onBlur={(e) => {}}
                            onChange={(plannedCourtsCount, e) => {
                                this.setState({ plannedCourtsCount });
                            }}
                            validationOption={{
                                type: 'number',
                                numberType: 'int',
                                showMsg: false,
                                check: true,
                                required: true,
                            }}
                        />
                        <CheckBox
                            label="Setup password?"
                            className="col-md-12 mb-3"
                            id="isPassword"
                            name="isPassword"
                            checked={this.state.isPassword}
                            onChange={(isPassword) => this.setState({ isPassword })}
                        />
                        <TextBox
                            label={
                                <label htmlFor="password" className="mb-0">
                                    <span>Password</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            id="password"
                            name="password"
                            type="password"
                            hide={!this.state.isPassword}
                            className="col-md-6 mb-3"
                            validate={this.state.validate}
                            value={password}
                            placeholder="Password"
                            onBlur={(e) => {}}
                            onChange={(password, e) => {
                                this.setState({ password });
                            }}
                            validationOption={{
                                showMsg: false,
                                check: true,
                                required: true,
                            }}
                        />
                        <TextBox
                            label={
                                <label htmlFor="confirmPass" className="mb-0">
                                    <span>Confirm Password</span>
                                    <span className="ml-1 h5 text-danger">*</span>
                                </label>
                            }
                            id="confirmPass"
                            name="confirmPass"
                            type="password"
                            hide={!this.state.isPassword}
                            className="col-md-6 mb-3"
                            validate={this.state.validate}
                            value={confirmPass}
                            placeholder="Password"
                            onBlur={(e) => {}}
                            onChange={(confirmPass, e) => {
                                this.setState({ confirmPass });
                            }}
                            validationOption={{
                                showMsg: false,
                                check: true,
                                required: true,
                            }}
                        />
                        <ComplexSelect
                            placeholder="Select Tags..."
                            label="Organization Tags"
                            className="col-md-12 mb-3"
                            isClearable
                            isMulti
                            value={getClubTags()}
                            options={generateOrgTagsOption()}
                            onChange={(tags) => {
                                this.setState({ orgTags: (tags || []).map((t) => t.value) });
                            }}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="row">
                        <div className="col-md-6 text-center">
                            {this.state.showDefaultOption && (
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="isDefault"
                                        checked={this.state.isDefault}
                                        onChange={() => {
                                            this.setState({ isDefault: !this.state.isDefault });
                                        }}
                                    />
                                    <label className="custom-control-label mt-1" htmlFor="isDefault">
                                        is default club?
                                    </label>
                                    <div className="text-danger">
                                        (*** this club will be used for only registration of members that is not member
                                        of special club and system can create only one default club ***)
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 d-flex justify-content-between align-items-center">
                            <Button
                                color="secondary"
                                className="btn-secondary btn btn-lg"
                                style={{ width: '150px' }}
                                onClick={this.cancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                className="btn-primary btn btn-lg"
                                style={{ width: '150px' }}
                                onClick={this.create}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { systemSettings } = user;
    return { systemSettings };
};

export default connect(mapStateToProps, {
    showMessage,
    handleHTTPError,
})(CreateClubDlg);
