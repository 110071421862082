import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { putSystemSettings, showMessage } from 'store/actions';
import RouteApp from './Route';
import toast from 'cogo-toast';
import SignIn from './Auth/SignIn';
import ForgotPass from './Auth/ForgotPass';
import ResetPass from './Auth/ResetPass';
import { getSysManageData } from 'apis';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            authUser ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

class Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMsg: false,
        };
    }

    componentDidMount = () => {
        getSysManageData({})
            .then((data) => {
                console.log(data);
                this.props.putSystemSettings(data.data);
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
            });
    };

    componentDidUpdate = (prevProps) => {
        if (!prevProps.showMsg && this.props.showMsg) {
            if (this.props.message !== null) {
                if (this.props.message.type === 'success') {
                    return toast
                        .success(this.props.message.text, { position: 'top-right', heading: 'Success' })
                        .then(() => {
                            this.props.showMessage({ showMsg: false, message: null });
                        });
                } else if (this.props.message.type === 'warning') {
                    return toast
                        .warn(this.props.message.text, { position: 'top-right', heading: 'Warning' })
                        .then(() => {
                            this.props.showMessage({ showMsg: false, message: null });
                        });
                } else {
                    return toast
                        .error(this.props.message.text, { position: 'top-right', heading: 'Error' })
                        .then(() => {
                            this.props.showMessage({ showMsg: false, message: null });
                        });
                }
            }
        }
    };

    render() {
        const { match, location, authUser, systemSettings } = this.props;

        if (!systemSettings) return null;

        if (location.pathname === '/') {
            if (authUser === null) {
                return <Redirect to={'/signin'} />;
            } else {
                return <Redirect to={'/dashboard'} />;
            }
        }

        return (
            <Switch>
                <Route path="/signin" component={SignIn} />
                <Route path="/forgotpass" component={ForgotPass} />
                <Route path="/resetpass/:email/:token" component={ResetPass} />
                <RestrictedRoute path={`${match.url}`} authUser={authUser} component={RouteApp} />
            </Switch>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser, systemSettings } = user;
    const { showMsg, message } = ui;
    return { authUser, showMsg, message, systemSettings };
};

export default connect(mapStateToProps, {
    showMessage,
    putSystemSettings,
})(Container);
