import React, { Component } from 'react';
import './index.scss';

export default class CheckBox extends Component {
    render() {
        if (this.props.hide) return null;
        return (
            <div className={`w-100 ${this.props.className || ''}`}>
                <div className="custom-control custom-checkbox my-1 mr-4">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        disabled={this.props.disabled}
                        id={this.props.id}
                        name={this.props.name}
                        checked={this.props.checked}
                        defaultChecked={this.props.defaultChecked}
                        onChange={() => {
                            this.props.onChange(!this.props.checked);
                        }}
                    />
                    <label className="custom-control-label mt-1" htmlFor={this.props.id}>
                        {this.props.label}
                    </label>
                </div>
            </div>
            // <div className={`w-100 checkbox-validation ${this.props.className || ''}`}>
            //     <Checkbox
            //         attributesWrapper={{}} // Optional.
            //         attributesInput={{ // Optional.
            //             id: this.props.id,
            //             name: this.props.name,
            //         }}
            //         labelHtml={<label className="mb-0">{this.props.label}</label>}
            //         disabled={this.props.disabled}
            //         validate = {this.props.validate}
            //         validationCallback={this.props.validationCallback}
            //         value={this.props.value}
            //         checked={this.props.checked}
            //         onBlur={this.props.onBlur}
            //         classNameInput={this.props.classNameInput}
            //         onChange={(value, e) => {
            //             if (this.props.onChange) this.props.onChange(value)
            //         }}
            //         validationOption={this.props.validationOption}
            //     />
            // </div>
        );
    }
}
