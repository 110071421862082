import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from 'rc-drawer';
import CustomScrollbars from 'components/CustomScrollbars';
import SidenavContent from './SidenavContent';
import SidebarLogo from './SidebarLogo';
import {toggleCollapsedNav, updateWindowWidth} from 'store/actions';
import './index.scss'

class SideBar extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {

        window.addEventListener('resize', () => {

            this.props.updateWindowWidth(window.innerWidth)
        });
    }

    onTouchEnd = () => {

        this.props.toggleCollapsedNav(false);
    }

    render() {

        const {navCollapsed, width} = this.props;

        let open = navCollapsed;

        if (width > 1200) {

            open = true
        }
        
        return (
            <Drawer
                open={open}
                onClose={this.onTouchEnd}
                defaultOpen={true}
                showMask={width <= 1200 ? true : false}
                handler={false}
                level={null}
                width="300px">
                <div className="side-nav  d-flex flex-column">
                    <SidebarLogo history={this.props.history}/>
                    <CustomScrollbars className="scrollbar">
                        <SidenavContent/>    
                    </CustomScrollbars>
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = ({ui, user}) => {
    const {navCollapsed, width} = ui;
    const {authUser} = user; 
    return {navCollapsed, width, authUser}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideBar));
