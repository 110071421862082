import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import IssueList from './list';
import IssueDetails from './details';

class IssuesReport extends Component {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.url}/:id`} component={IssueDetails} />
                <Route path={`${this.props.match.url}`} component={IssueList} />
            </Switch>
        );
    }
}

export default IssuesReport;
