import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import moment from 'moment';
import PageContentLayout from 'components/PageContentLayout';

class ClubView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {};

    render() {
        const { club } = this.props;
        if (!club) return null;

        const requestCycles = (templates) => {
            if (templates.length === 0) {
                return <h3 className="text-danger">No Cycle</h3>;
            }
            return templates.map((tp, index) => {
                return (
                    <h3 key={index} className="font-weight-bold">
                        {moment(tp.stDate, 'YYYY-MM-DD').format('MMM DD')} ~{' '}
                        {moment(tp.etDate, 'YYYY-MM-DD').format('MMM DD')}
                    </h3>
                );
            });
        };

        const generateVenueDetails = () => {
            return club.venues.map((venue, index) => {
                return (
                    <div key={index} className="mb-3 border p-4">
                        <h2 className="mb-4 font-weight-bold">{venue.displayName}</h2>
                        <Row>
                            <Col md={4}>
                                <h5>Activated Court / Total Court</h5>
                                <h3 className="mb-4 font-weight-bold">
                                    {venue.setting.activatedCourts.length} / {venue.setting.courtCount}
                                </h3>
                                <h5>Open Hours</h5>
                                <h3 className="font-weight-bold">
                                    {venue.setting.timeSlots[0]} ~{' '}
                                    {venue.setting.timeSlots[venue.setting.timeSlots.length - 1]}
                                </h3>
                            </Col>
                            <Col md={4}>
                                <h5>Total Requests</h5>
                                <h3 className="mb-4 font-weight-bold">{venue.requests}</h3>
                                <h5>Total Confirmed Reservations</h5>
                                <h3 className="font-weight-bold">{venue.rses}</h3>
                            </Col>
                            <Col md={4}>
                                <h5>Current Request Cycle</h5>
                                {requestCycles(venue.releasedTemplates)}
                                <h5 className="mt-4">Current Schedule Cycle</h5>
                                <h3 className="font-weight-bold">
                                    {moment(venue.rsStDate, 'YYYY-MM-DD').format('MMM DD')} ~{' '}
                                    {moment(venue.rsEtDate, 'YYYY-MM-DD').format('MMM DD')}
                                </h3>
                            </Col>
                        </Row>
                    </div>
                );
            });
        };

        return <PageContentLayout title="Reservation Venue Summary">{generateVenueDetails()}</PageContentLayout>;
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser } = user;
    const { isLoader } = ui;
    return { authUser, isLoader };
};
export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(ClubView)
);
