import React, { Component } from 'react';
import { Select } from 'react-inputs-validation';
import './index.scss';

export default class SelectItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.hide) return null;

        return (
            <div className={`w-100 text-left ${this.props.className || ''}`}>
                {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
                <Select
                    attributesWrapper={{}}
                    attributesInput={{
                        id: this.props.id,
                        name: this.props.id,
                    }}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    optionList={this.props.optionList}
                    classNameWrapper="select__wrapper"
                    classNameContainer="select__container"
                    classNameSelect="select__select"
                    classNameOptionListWrapper="select__option-list__wrapper"
                    classNameOptionListContainer="select__option-list__container"
                    classNameOptionListItem="select__option-list__item"
                    onChange={(value) => {
                        this.props.onChange(value);
                    }}
                    validationOption={{
                        ...this.props.validationOption,
                        showMsg: false,
                    }}
                />
            </div>
        );
    }
}
