import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import { changeClubPassword } from 'apis';
import TextBox from 'components/controls/TextBox';
import PageContentLayout from 'components/PageContentLayout';

class ClubPasswordSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            validate: false,
        };
    }

    componentDidMount = () => {};

    update = () => {
        this.setState({ validate: true });
        if (!this.state.newPassword || !this.state.confirmPassword) return;
        if (this.state.newPassword !== this.state.confirmPassword) {
            return this.props.showMessage({
                show: true,
                message: { type: 'error', text: "Password doesn't matched." },
            });
        }
        const params = {
            clubId: this.props.club._id,
            newPassword: this.state.newPassword,
        };
        this.props.showLoader(true);
        changeClubPassword(params)
            .then((data) => {
                this.props.showLoader(false);
                this.setState({ newPassword: '', confirmPassword: '' });
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: data.message },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        const { club } = this.props;
        if (!club) return null;

        return (
            <PageContentLayout title="Set Password">
                <Row>
                    <Col md={3}>
                        <label htmlFor="ID">New Password</label>
                        <TextBox
                            tabIndex="0"
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            validate={this.state.validate}
                            value={this.state.newPassword}
                            placeholder="New Password"
                            onChange={(newPassword, e) => {
                                this.setState({
                                    newPassword: newPassword,
                                });
                            }}
                            validationOption={{
                                name: 'New Password',
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                    </Col>
                    <Col md={3}>
                        <label htmlFor="ID">Confirm Password</label>
                        <TextBox
                            tabIndex="0"
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            validate={this.state.validate}
                            value={this.state.confirmPassword}
                            placeholder="Confirm Password"
                            onChange={(confirmPassword, e) => {
                                this.setState({
                                    confirmPassword: confirmPassword,
                                });
                            }}
                            validationOption={{
                                name: 'Confirm Password',
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                    </Col>
                    <Col md={3}>
                        <label></label>
                        <br />
                        <Button
                            color="secondary"
                            className="btn btn-secondary btn-lg btn-block mt-2"
                            onClick={this.update}
                        >
                            Change
                        </Button>
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser } = user;
    const { isLoader } = ui;
    return { authUser, isLoader };
};
export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(ClubPasswordSetting)
);
