import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import { getClub } from 'apis';
import RejectRequestRsServiceDlg from 'components/RejectRequestRsServiceDlg';
import PasswordSetting from './PasswordSetting';
import ProfileSetting from './ProfileSetting';
import RsServiceSummary from './RsServiceSummary';
import CmtyEventSetting from './ClubServiceFeeSettings';
import OrgTagsSetting from './OrgTagsSetting';

class ClubView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            club: null,
        };
    }

    componentDidMount = () => {
        const params = {
            _id: this.props.match.params.id,
        };
        this.props.showLoader(true);
        getClub(params)
            .then((data) => {
                this.props.showLoader(false);
                const club = data.data;
                this.setState({ club });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        const { club } = this.state;
        if (!club) return null;

        return (
            <>
                <ProfileSetting club={this.state.club} />
                <PasswordSetting club={this.state.club} />
                <OrgTagsSetting club={this.state.club} />
                {club.allowCmtyEvent && <CmtyEventSetting club={this.state.club} />}
                <RsServiceSummary club={this.state.club} />
                <RejectRequestRsServiceDlg
                    isOpen={this.state.isOpenRejectRsService}
                    toggle={this.toggleRejectRsService}
                    callback={this.rejectRsFullService}
                />
            </>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser } = user;
    const { isLoader } = ui;
    return { authUser, isLoader };
};
export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(ClubView)
);
