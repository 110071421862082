import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { validateEmail } from 'utils';
import { showLoader, showMessage, userUpdateSuccess, handleHTTPError } from 'store/actions';
import { updateProfile, changePassword } from 'apis';
import TextBox from 'components/controls/TextBox';
import PageContentLayout from 'components/PageContentLayout';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            mobilePhone: '',
            newPassword: '',
            confirmPassword: '',
            currentPassword: '',
            profileValidate: false,
            passwordValidate: false,
        };
    }

    componentDidMount() {
        if (this.props.authUser) {
            this.setState({
                name: this.props.authUser.name || '',
                email: this.props.authUser.email || '',
                mobilePhone: this.props.authUser.mobilePhone || '',
            });
        }
    }

    changePass = () => {
        this.setState({
            passwordValidate: true,
        });
        if (this.state.currentPassword === '' || this.state.newPassword === '' || this.state.confirmPassword === '') {
            return;
        }
        if (this.state.newPassword !== this.state.confirmPassword) {
            this.props.showMessage({
                show: true,
                message: {
                    type: 'error',
                    text: "Password doesn't matched.",
                },
            });
            return;
        }
        const params = {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
        };
        this.props.showLoader(true);
        changePassword(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: data.message,
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    profileSave = () => {
        this.setState({
            profileValidate: true,
        });
        if (
            this.state.name === '' ||
            this.state.email === '' ||
            this.state.mobilePhone === '' ||
            this.state.logoUrl === ''
        ) {
            return;
        }
        if (!validateEmail(this.state.email)) {
            return;
        }
        const params = {
            name: this.state.name,
            email: this.state.email,
            mobilePhone: this.state.mobilePhone,
        };
        this.props.showLoader(true);
        updateProfile(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.userUpdateSuccess(data.data);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Profile updated.',
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        return (
            <>
                <PageContentLayout title="Super Administrator Profile">
                    <Row>
                        <Col md={3}>
                            <label htmlFor="ID">Display Name</label>
                            <TextBox
                                tabIndex="2"
                                id="name"
                                name="name"
                                type="text"
                                validate={this.state.profileValidate}
                                value={this.state.name}
                                placeholder="Display Name"
                                onChange={(name, e) => {
                                    this.setState({
                                        name: name,
                                    });
                                }}
                                validationOption={{
                                    name: 'Display Name',
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <label htmlFor="ID">Email</label>
                            <TextBox
                                tabIndex="3"
                                id="email"
                                name="email"
                                type="text"
                                validate={this.state.profileValidate}
                                value={this.state.email}
                                placeholder="Email"
                                onChange={(email, e) => {
                                    this.setState({
                                        email: email,
                                    });
                                }}
                                validationOption={{
                                    name: 'Email',
                                    check: true,
                                    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    regMsg: 'Invalid Email',
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <label htmlFor="ID">Mobile Phone</label>
                            <TextBox
                                tabIndex="4"
                                id="mobilePhone"
                                name="mobilePhone"
                                type="text"
                                validate={this.state.profileValidate}
                                value={this.state.mobilePhone}
                                placeholder="Mobile Phone"
                                onChange={(mobilePhone, e) => {
                                    this.setState({
                                        mobilePhone: mobilePhone,
                                    });
                                }}
                                validationOption={{
                                    name: 'Mobile Phone',
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <label>&nbsp;</label>
                            <Button
                                color="secondary"
                                className="btn btn-secondary btn-lg btn-block"
                                onClick={this.profileSave}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </PageContentLayout>
                <PageContentLayout title="Change Password">
                    <Row>
                        <Col md={3}>
                            <label htmlFor="ID">Current Password</label>
                            <TextBox
                                tabIndex="0"
                                id="currentPassword"
                                name="currentPassword"
                                type="password"
                                validate={this.state.passwordValidate}
                                value={this.state.currentPassword}
                                placeholder="Current Password"
                                onChange={(currentPassword, e) => {
                                    this.setState({
                                        currentPassword: currentPassword,
                                    });
                                }}
                                validationOption={{
                                    name: 'Current Password',
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <label htmlFor="ID">New Password</label>
                            <TextBox
                                tabIndex="0"
                                id="newPassword"
                                name="newPassword"
                                type="password"
                                validate={this.state.passwordValidate}
                                value={this.state.newPassword}
                                placeholder="New Password"
                                onChange={(newPassword, e) => {
                                    this.setState({
                                        newPassword: newPassword,
                                    });
                                }}
                                validationOption={{
                                    name: 'New Password',
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <label htmlFor="ID">Confirm Password</label>
                            <TextBox
                                tabIndex="0"
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                validate={this.state.passwordValidate}
                                value={this.state.confirmPassword}
                                placeholder="Confirm Password"
                                onChange={(confirmPassword, e) => {
                                    this.setState({
                                        confirmPassword: confirmPassword,
                                    });
                                }}
                                validationOption={{
                                    name: 'Confirm Password',
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <label>&nbsp;</label>
                            <Button
                                color="secondary"
                                className="btn btn-secondary btn-lg btn-block"
                                onClick={this.changePass}
                            >
                                Change
                            </Button>
                        </Col>
                    </Row>
                </PageContentLayout>
            </>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser } = user;
    return { authUser, isLoader };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        userUpdateSuccess,
        handleHTTPError,
    })(Profile)
);
