import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showMessage, handleHTTPError } from 'store/actions';
import { createMember } from 'apis';
import TextBox from 'components/controls/TextBox';
import CircularProgress from 'components/CircularProgress';
import { validateEmail } from 'utils';

class AddMemberDlg extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            validate: false,
            loading: false,
        };
    }

    componentDidMount() {}

    cancel = () => {
        this.setState({
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            loading: false,
            validate: false,
        });
        this.props.toggle();
    };

    createMember = () => {
        if (this.state.loading) return;
        this.setState({ validate: true });
        if (!this.state.email || !this.state.firstName || !this.state.lastName || !validateEmail(this.state.email))
            return;

        const params = {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phoneNumber: this.state.phoneNumber,
            isCmtyEventUser: true,
        };
        this.setState({ loading: true });
        createMember(params)
            .then((data) => {
                this.setState({ loading: false });
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: 'New member has been added.' },
                });
                this.props.callback();
                this.cancel();
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.props.handleHTTPError(error);
            });
    };

    render() {
        return (
            <div className="add-user-modal">
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.cancel}
                    className={this.props.className}
                    backdrop={'static'}
                >
                    <ModalHeader toggle={this.cancel} className="position-relative">
                        Add New Community Event member
                        {this.state.loading && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '30px',
                                    height: '60px',
                                    width: '60px',
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <TextBox
                                label={
                                    <label className="mb-0 " htmlFor="name">
                                        First Name <span className="text-danger">*</span>
                                    </label>
                                }
                                id="firstName"
                                name="firstName"
                                type="text"
                                className="col-md-6 mb-2"
                                value={this.state.firstName}
                                placeholder="John"
                                validate={this.state.validate}
                                onBlur={() => {}}
                                onChange={(firstName, e) => {
                                    this.setState({
                                        firstName: firstName.trim(),
                                    });
                                }}
                                validationOption={{
                                    showMsg: false,
                                    name: 'First Name',
                                    check: true,
                                    required: true,
                                }}
                            />

                            <TextBox
                                label={
                                    <label className="mb-0 " htmlFor="name">
                                        Last Name <span className="text-danger">*</span>
                                    </label>
                                }
                                id="lastName"
                                name="lastName"
                                className="col-md-6 mb-2"
                                type="text"
                                value={this.state.lastName}
                                placeholder="Michael"
                                validate={this.state.validate}
                                onBlur={() => {}}
                                onChange={(lastName, e) => {
                                    this.setState({
                                        lastName: lastName.trim(),
                                    });
                                }}
                                validationOption={{
                                    showMsg: false,
                                    name: 'Last Name',
                                    check: true,
                                    required: true,
                                }}
                            />
                            <TextBox
                                label={
                                    <label className="mb-0 " htmlFor="name">
                                        Email <span className="text-danger">*</span>
                                    </label>
                                }
                                id="email"
                                name="email"
                                type="text"
                                className="col-md-6 mb-2"
                                value={this.state.email}
                                placeholder="example@mail.com"
                                validate={this.state.validate}
                                onBlur={() => {}}
                                onChange={(email, e) => {
                                    this.setState({
                                        email: email.trim(),
                                    });
                                }}
                                validationOption={{
                                    showMsg: false,
                                    name: 'Email',
                                    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    regMsg: 'Invalid Email',
                                    check: true,
                                    required: true,
                                }}
                            />
                            <TextBox
                                label="Phone Number"
                                id="phoneNumber"
                                name="phoneNumber"
                                className="col-md-6 mb-2"
                                type="text"
                                value={this.state.phoneNumber}
                                placeholder="4159875236"
                                onChange={(phoneNumber, e) => {
                                    this.setState({
                                        phoneNumber: phoneNumber.trim(),
                                    });
                                }}
                                validationOption={{
                                    showMsg: false,
                                    name: 'phoneNumber',
                                    check: false,
                                    required: false,
                                }}
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" size="lg" outline className="w-25" onClick={this.cancel}>
                            Cancel
                        </Button>
                        <Button color="primary" size="lg" className="w-25" onClick={this.createMember}>
                            Create
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser, memberTypes } = user;
    return { authUser, memberTypes };
};

export default withRouter(
    connect(mapStateToProps, {
        showMessage,
        handleHTTPError,
    })(AddMemberDlg)
);
