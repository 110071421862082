export default {
    BASE_URL: process.env.REACT_APP_SERVER_URL,

    // auth
    SIGNIN_URL: '/api/admin/login',
    UPDATE_PROFILE_URL: '/api/admin/update',
    CHANGEPASS_URL: '/api/admin/change_password',
    FORGOTPASS_URL: '/api/admin/forgot_password',
    RESETPASS_URL: '/api/admin/reset_password',
    VERIFY_URL: '/api/admin/verify',
    VERIFYCODE_URL: '/api/admin/verify_code',
    REPOrT_COURTS: '/api/admin/report_courts',

    //admin
    GET_DASHBOARDDATA_URL: '/api/admin/dash_summary',

    CREATE_CLUB_URL: '/api/admin/create-club',
    GET_CLUBS_URL: '/api/admin/get-clubs',
    BLOCK_CLUB_URL: '/api/admin/block-club',
    ACTIVE_CLUB_URL: '/api/admin/active-club',
    ACCEPT_CLUBRSSERVICE_URL: '/api/admin/accept-clubrsservice',
    REJECT_CLUBRSSERVICE_URL: '/api/admin/reject-clubrsservice',
    CONTROL_CLUBFEATURE_URL: '/api/admin/control-clubfeature',
    GET_CLUB_URL: '/api/admin/get_club',
    RESET_CLUBPASS_URL: '/api/admin/club_reset_password',

    UPDATE_CLUBSETTINGS_URL: '/api/clubsetting/update',
    GET_DBBACKUPFILES_URL: '/api/admin/get_dbbackup_files',
    RESTORE_DB_URL: '/api/admin/restore_db',
    BACKUP_DB_URL: '/api/admin/backup_db',
    DOWNLOAD_BACKUPFILE_URL: '/api/admin/download_backupfile',
    UPLOAD_BACKUPFILE_URL: '/api/admin/upload_backupfile',
    UPDATE_CLUBPASS_URL: '/api/club/change_password',
    UPDATE_CLUBPROFILE_URL: '/api/club/update',
    SEARCH_CLUB_URL: '/api/club/search',
    SEARCH_REPORTS_URL: '/api/report/search',
    GET_REPORT_URL: '/api/report',
    RESOLVE_REPORT_URL: '/api/report/resolve',
    GET_SYSMANAGE_URL: '/api/sysmanage/get',
    UPDATE_SYSMANAGE_URL: '/api/sysmanage/update',

    UPDATE_POST_URL: '/api/post/update',
    SEARCH_POST_URL: '/api/post/search',
    GET_POST_URL: '/api/post',
    DELETE_POST_URL: '/api/post/delete',

    CREATE_COMMENT_URL: '/api/comment/create',
    UPDATE_COMMENT_URL: '/api/comment/update',
    PUBLISH_COMMENT_URL: '/api/comment/publish',
    SEARCH_COMMENT_URL: '/api/comment/search',
    GET_COMMENT_URL: '/api/comment',
    DELETE_COMMENT_URL: '/api/comment/delete',

    RETRIEVE_MEMBER_URL: '/api/user/search',
    CREATE_MEMBER_URL: '/api/user/signup',
    UPDATE_MEMBER_URL: '/api/user/update',
    READ_MEMBER_URL: '/api/user',

    INVOICE_URL: '/api/invoice',
    CMTY_EVENT_URL: '/api/cmtyevent',

    PAYMENT_FEESETTING_URL: '/api/paymentfee-setting',

    UPLOAD_FILE_URL: '/api/upload/general',

    DEVELOPER_URL: '/api/developer',
};
