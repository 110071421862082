import {
    SHOW_MESSAGE,
    TOGGLE_COLLAPSED_NAV,
    WINDOW_WIDTH,
    SHOW_LOADER,
    TOGGLE_SCHED_PANEL, 
} from 'store/actionTypes';

export const toggleCollapsedNav = (isNavCollapsed) => {
    return {
        type: TOGGLE_COLLAPSED_NAV, 
        payload: isNavCollapsed
    };
};

export const toggleSchedPanel = (showSchedPanel) => {
    return {
        type: TOGGLE_SCHED_PANEL, 
        payload: showSchedPanel
    };
};

export const updateWindowWidth = (width) => {
    return {
        type: WINDOW_WIDTH,
        payload: width
    };
}

export const showMessage = (data) => {
    return {
        type: SHOW_MESSAGE,
        payload: data
    };
};

export const showLoader = (data) => {
    return {
        type: SHOW_LOADER,
        payload: data,
    };
};

