import React, { Component } from 'react';
import { Textarea } from 'react-inputs-validation';
// import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import './index.scss';

export default class TextArea extends Component {
    render() {
        if (this.props.hide) return null;
        return (
            <div className={`w-100 ${this.props.className || ''}`}>
                {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
                <Textarea
                    attributesWrapper={{}} // Optional.
                    attributesInput={{
                        // Optional.
                        id: this.props.id,
                        name: this.props.name,
                        placeholder: this.props.placeholder,
                        rows: this.props.rows,
                    }}
                    disabled={this.props.disabled}
                    validate={this.props.validate}
                    validationCallback={this.props.validationCallback}
                    value={this.props.value}
                    onBlur={this.props.onBlur}
                    classNameInput={`textarea__input ${this.props.disabled && 'disabled'}`}
                    onChange={(value, e) => {
                        if (this.props.onChange) this.props.onChange(value);
                    }}
                    validationOption={this.props.validationOption}
                />
            </div>
        );
    }
}
