import React, { Component } from 'react';
import { Textbox } from 'react-inputs-validation';
import './index.scss';

export default class TextBox extends Component {
    render() {
        if (this.props.hide) return null;
        return (
            <div className={`w-100 ${this.props.className || ''}`} style={this.props.style || {}}>
                {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
                <div className=" position-relative">
                    <Textbox
                        attributesWrapper={{}} // Optional.
                        attributesInput={{
                            // Optional.
                            id: this.props.id,
                            name: this.props.name,
                            type: this.props.type,
                            placeholder: this.props.placeholder,
                        }}
                        disabled={this.props.disabled}
                        validate={this.props.validate}
                        validationCallback={this.props.validationCallback}
                        value={this.props.value}
                        onBlur={this.props.onBlur}
                        classNameWrapper={`textbox__wrapper ${this.props.size || 'lg'}`}
                        classNameInput={`textbox__input ${this.props.size || 'lg'} ${
                            this.props.disabled && 'disabled'
                        }`}
                        customStyleInput={this.props.prefix && { paddingLeft: '25px' }}
                        onChange={(value, e) => {
                            if (this.props.onChange) this.props.onChange(value.trim());
                        }}
                        validationOption={this.props.validationOption}
                    />
                    {this.props.suffix && <div className="suffix">{this.props.suffix}</div>}
                    {this.props.prefix && <div className="prefix">{this.props.prefix}</div>}
                </div>
            </div>
        );
    }
}
