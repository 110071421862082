import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ClubManage from './Manage';
import ClubView from './View';

class Users extends Component {
    render() {
        return (
            <div id="users">
                <Switch>
                    <Route exact path={`${this.props.match.url}`} component={ClubManage} />
                    <Route exact path={`${this.props.match.url}/:id`} component={ClubView} />
                </Switch>
            </div>
        );
    }
}

export default Users;
