import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toggleCollapsedNav } from 'store/actions';
import UserInfo from './UserInfo'
import { Constants } from 'utils/constants';
import './index.scss'

class Header extends React.Component {

    toggleUserInfo = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    constructor() {
        super();
        this.state = {
            userInfo: false
        }
    }

    render() {

        return (
            <div className="app-main-header">
                <div className="d-flex app-toolbar">
                    <div className="app-logo-bl align-items-center d-flex">
                        <span className={`jr-menu-icon pointer d-flex d-xl-none`}
                            onClick={this.onToggleCollapsedNav}>
                            <span className="menu-icon bg-white" /></span>
                    </div>
                    <ul className="header-notifications list-inline ml-auto" style={{ position: 'relative', zIndex: 2 }}>
                        <li className="list-inline-item user-nav pointer">
                            <Dropdown
                                className="quick-menu"
                                isOpen={this.state.userInfo}
                                toggle={this.toggleUserInfo.bind(this)}>
                                <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown">
                                    <img
                                        alt='...'
                                        src={this.props.authUser.logoUrl || Constants.DEFAULT_LOGO}
                                        className="user-avatar size-30 mr-2" />
                                    <FontAwesomeIcon icon="caret-down" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <UserInfo history={this.props.history} toggle={this.toggleUserInfo} />
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user, venue }) => {
    const { authUser } = user;
    return { authUser };
}

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav })(Header));