import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import ComplexSelect from 'components/controls/ComplexSelect';
import './index.scss';

const services = ['Paypal', 'Bank', 'Other'];

class ChooseInvoicePayMethodDlg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preferredService: 'paypal',
        };
    }

    componentDidMount() {}

    cancel = () => {
        this.setState({
            preferredService: 'paypal',
        });
        this.props.toggle();
    };

    ok = () => {
        this.props.callback(this.state.preferredService);
        this.cancel();
    };

    render() {
        const getServiceValue = () => {
            return {
                value: this.state.preferredService.toLowerCase(),
                label: `${this.state.preferredService[0].toUpperCase()}${this.state.preferredService.slice(1)}`,
            };
        };

        const generateServiceOptions = () => {
            return services.map((service) => {
                return { value: service.toLowerCase(), label: `${service[0].toUpperCase()}${service.slice(1)}` };
            });
        };

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.cancel} backdrop={'static'} id="choose-invoicepaymethod">
                <ModalHeader toggle={this.cancel} className="position-relative">
                    Choose Payment Service
                </ModalHeader>
                <ModalBody className="px-4 py-3">
                    <p className="text-center text-danger">*** You need to pay via this service manually. ***</p>
                    <ComplexSelect
                        placeholder="Select Service..."
                        label="Payment Service"
                        value={getServiceValue()}
                        options={generateServiceOptions()}
                        onChange={(service) => {
                            this.setState({ preferredService: service?.value });
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" size="lg" className="px-4" onClick={this.cancel}>
                        Cancel
                    </Button>
                    <Button color="primary" size="lg" className="px-5" onClick={this.ok}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ChooseInvoicePayMethodDlg;
