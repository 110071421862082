import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { showLoader, showMessage, handleHTTPError, putSystemSettings } from 'store/actions';
import { updateSysManage } from 'apis';
import TextBox from 'components/controls/TextBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ZipCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zipCodes: [],
            validate: false,
        };
        this.newZipCodeRef = React.createRef();
    }

    componentDidMount = () => {
        let zipCodes = this.props.systemSettings.zipCodes || [];
        if (zipCodes.length === 0) zipCodes = [{ value: '', name: '' }];
        this.setState({ zipCodes });
    };

    update = () => {
        this.setState({ validate: true });

        // check empty geographic area codes
        if (this.state.zipCodes.some((code) => !code.value || !code.name)) return;

        // check zipcode prefix
        if (this.state.zipCodes.some((code) => code.value?.length !== 2)) return;

        // check duplicated zipcode
        let dupArea = this.state.zipCodes.find(
            (code, index, self) => self.findIndex((c) => c.value === code.value) !== index
        );
        if (dupArea)
            this.props.showMessage({
                show: true,
                message: { type: 'warning', text: `Duplicated zip code "${dupArea.value}"` },
            });

        // check duplicated Area name
        dupArea = this.state.zipCodes.find(
            (value, index, self) => self.findIndex((v) => v.name.toLowerCase() === value.name.toLowerCase()) !== index
        );
        if (dupArea)
            this.props.showMessage({
                show: true,
                message: { type: 'warning', text: `Duplicated zip code "${dupArea.name}"` },
            });

        const params = {
            _id: this.props.systemSettings._id,
            zipCodes: this.state.zipCodes,
        };
        this.props.showLoader(true);
        updateSysManage(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.putSystemSettings(data.data);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Geographic Areas has been updated',
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    add = () => {
        const zipCodes = [...this.state.zipCodes, { value: '', name: '' }];
        this.setState({ validate: false, zipCodes });
        setTimeout(() => {
            this.newZipCodeRef.current.scroll({ top: this.newZipCodeRef.current.scrollHeight, behavior: 'smooth' });
        }, 300);
    };

    delete = async (index) => {
        const newZipCodes = [...this.state.zipCodes];
        if (newZipCodes[index].value || newZipCodes[index].name) {
            const ok = await swal({
                title: 'Warning',
                text: `Do you want to remove this Zip Code? \n Are you sure?`,
                dangerMode: true,
                buttons: ['No', 'Yes'],
                icon: 'warning',
            });
            if (!ok) {
                return;
            }
        }
        newZipCodes.splice(index, 1);
        this.setState({ zipCodes: newZipCodes });
    };

    render() {
        const displayZipCodes = () => {
            return this.state.zipCodes.map((code, index) => {
                return (
                    <div key={index} className="mb-3 d-flex">
                        <TextBox
                            id={`prefix${index}`}
                            name={`prefix${index}`}
                            type="text"
                            className="mr-3"
                            onBlur={() => {}}
                            validate={this.state.validate}
                            value={code.value || ''}
                            placeholder="Prefix of zipcode (2 digits)"
                            onChange={(value, e) => {
                                const zipCodes = [...this.state.zipCodes];
                                zipCodes[index].value = value;
                                this.setState({ zipCodes });
                            }}
                            validationOption={{
                                type: 'number',
                                numberType: 'int',
                                required: true,
                                length: 2,
                                check: true,
                                showMsg: false,
                            }}
                        />
                        <TextBox
                            id={`areaname${index}`}
                            name={`areaname${index}`}
                            onBlur={() => {}}
                            type="text"
                            validate={this.state.validate}
                            value={code.name || ''}
                            placeholder="Geographic Area Name"
                            onChange={(value, e) => {
                                const zipCodes = [...this.state.zipCodes];
                                zipCodes[index].name = value;
                                this.setState({ zipCodes });
                            }}
                            validationOption={{
                                required: true,
                                check: true,
                                showMsg: false,
                            }}
                        />
                        <div
                            className="text-secondary h1 d-flex justify-content-center align-items-end mb-2 pointer w-25"
                            onClick={() => {
                                this.delete(index);
                            }}
                        >
                            <FontAwesomeIcon icon="times" />
                        </div>
                    </div>
                );
            });
        };

        return (
            <>
                <h2 className="mb-4 font-weight-bold text-center">Geographic Area</h2>
                <div className="d-flex justify-content-around pr-5">
                    <label>Zipcode prefix (2 digits)</label>
                    <label>Geographic Area Name</label>
                </div>
                <div
                    style={{ maxHeight: '500px', overflowY: 'auto' }}
                    className="border py-3 pl-3 mb-4"
                    ref={this.newZipCodeRef}
                >
                    {displayZipCodes()}
                </div>
                <Row>
                    <Col md={6}>
                        <Button color="primary" className="btn btn-lg btn-block" onClick={this.add}>
                            <FontAwesomeIcon icon="plus" className="mr-3" />
                            Add New Area
                        </Button>
                    </Col>
                    <Col md={6}>
                        <Button color="secondary" className="btn btn-lg btn-block" onClick={this.update}>
                            <FontAwesomeIcon icon="save" className="mr-3" />
                            Save
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser, systemSettings } = user;
    return { authUser, systemSettings };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
        putSystemSettings,
    })(ZipCodes)
);
