import { USER_SIGNOUT_SUCCESS, USER_UPDATE_SUCCESS, SET_USER_SEARCHPARAMS, PUT_SYSTEM_SETTINGS } from '../actionTypes';

export const userUpdateSuccess = (data) => {
    return {
        type: USER_UPDATE_SUCCESS,
        payload: data,
    };
};

export const userSignOutSuccess = () => {
    return {
        type: USER_SIGNOUT_SUCCESS,
    };
};

export const setMemberSearchParams = (payload) => {
    return {
        type: SET_USER_SEARCHPARAMS,
        payload: payload,
    };
};

export const putSystemSettings = (payload) => {
    return {
        type: PUT_SYSTEM_SETTINGS,
        payload,
    };
};
