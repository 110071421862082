import axios from 'axios';
import API_URL from './ApiUrl'
import store from 'store';
import { Constants } from 'utils/constants';

axios.interceptors.request.use(function (config) {

	config.baseURL = API_URL.BASE_URL;
	config.timeout = 30000;

	const token = store.get('token');

	config.headers.token = token || '';
	config.headers.versionNumber = Constants.VERSION_NUMBER

	return config;
});


axios.interceptors.response.use(function (response) {
	if (response?.data?.code && response?.data?.code !== 200) {
		return Promise.reject({
			response: {
				status: response?.data?.code,
				data: {
					message: response?.data?.message || ''
				}
			}
		})
	}

	return response
}, function (error) {
	if (!error.response) return Promise.reject({
		response: {
			status: 408,
			data: {
				message: 'Request Timeout'
			}
		}
	})

	console.log('API Error ---', error)
	return Promise.reject(error);
});

export default axios;