import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { showLoader, showMessage, userSignOutSuccess, userUpdateSuccess, handleHTTPError } from 'store/actions';
import { findClubsWithDetails, reportCourts } from 'apis';
import { Constants } from 'utils/constants';

import { downloadCSV } from 'utils';
import PageContentLayout from 'components/PageContentLayout';

class CourtsReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalData: [],
            searchData: [],
            pageData: [],
            currentPage: 1,
            countPerPage: 10,
            searchWord: '',
            validate: false,
        };

        this.changePaination = this.changePaination.bind(this);
        this.search = this.search.bind(this);
        this.downloadRsCSV = this.downloadRsCSV.bind(this);
    }

    componentDidMount() {
        this.getClubs();
    }

    getClubs() {
        this.props.showLoader(true);
        findClubsWithDetails({})
            .then((data) => {
                this.props.showLoader(false);
                const newPageData = data.data.length > 0 ? data.data.slice(0, this.state.countPerPage) : [];
                this.setState({
                    totalData: data.data,
                    pageData: newPageData,
                    searchData: data.data,
                    currentPage: 1,
                    firstName: '',
                    lastName: '',
                    email: '',
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    }

    changePaination(page) {
        this.setState({
            currentPage: page,
            pageData: this.state.searchData.slice(this.state.countPerPage * (page - 1), this.state.countPerPage * page),
        });
    }

    search() {
        let newData = this.state.totalData;
        if (this.state.searchWord !== '') {
            newData = newData.filter(
                (data) =>
                    data.email.toLowerCase().indexOf(this.state.searchWord.toLowerCase()) > -1 ||
                    data.mobilePhone.indexOf(this.state.searchWord) > -1 ||
                    data.displayName.toLowerCase().indexOf(this.state.searchWord.toLowerCase()) > -1
            );
        }
        this.setState({
            searchData: newData,
            pageData: newData.slice(
                this.state.countPerPage * (this.state.currentPage - 1),
                this.state.countPerPage * this.state.currentPage
            ),
        });
    }

    downloadRsCSV() {
        try {
            if (this.state.totalData.length === 0) {
                return;
            }
            const stDate = moment(this.props.authUser.courtReportEtDate || new Date(), 'YYYY-MM-DD');
            const etDate = moment(new Date(), 'YYYY-MM-DD');
            this.props.showLoader(true);
            reportCourts({
                date: etDate.format('YYYY-MM-DD'),
            })
                .then((data) => {
                    this.props.showLoader(false);
                    let csvDatas = [];
                    this.state.totalData.forEach((club, index) => {
                        csvDatas.push({
                            No: index,
                            'Club Name': club.displayName,
                            Email: club.email,
                            'Count of Total Courts': club.totalCourts,
                            'Count of Courts Planned': club.plannedCourtsCount,
                            'Average Count of Courts Active':
                                club.atvCourtsHis.length > 0
                                    ? club.atvCourtsHis.reduce((a, b) => a + b, 0) / club.atvCourtsHis.length
                                    : club.activatedCourtsCount,
                            'Count of Courts Active': club.activatedCourtsCount,
                        });
                    });
                    downloadCSV(
                        csvDatas,
                        `CourtReport(${stDate.format('MMM/DD/YYYY')}-${etDate.format('MMM/DD/YYYY')})`
                    );
                    const newUser = { ...this.props.authUser };
                    newUser.courtReportEtDate = etDate.format('YYYY-MM-DD');
                    this.props.userUpdateSuccess(newUser);
                    this.props.showMessage({
                        show: true,
                        message: {
                            text: data.message,
                            type: 'success',
                        },
                    });
                    this.getClubs();
                })
                .catch((error) => {
                    this.props.showLoader(false);
                    this.props.handleHTTPError(error);
                });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const generateBody = () => {
            return this.state.pageData.map((club, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="d-flex justify-content-center">
                            <img
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '100%',
                                    border: '1px solid #ccc',
                                }}
                                src={club.logoUrl || Constants.DEFAULT_LOGO}
                                alt="logo"
                            />
                        </td>
                        <td>{club.displayName}</td>
                        <td>{club.email}</td>
                        <td>{club.totalCourts}</td>
                        <td>{club.plannedCourtsCount}</td>
                        <td>
                            {club.atvCourtsHis.length > 0
                                ? club.atvCourtsHis.reduce((a, b) => a + b, 0) / club.atvCourtsHis.length
                                : club.activatedCourtsCount}
                        </td>
                        <td>{club.activatedCourtsCount}</td>
                    </tr>
                );
            });
        };

        return (
            <PageContentLayout title="Courts Report">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>
                        Latest Report Date:{' '}
                        {moment(this.props.authUser.courtReportEtDate || this.props.authUser.createdAt).format(
                            'MMM DD, YYYY'
                        )}
                    </h2>
                    <Button
                        color="secondary"
                        className="btn btn-lg btn-secondary ml-3"
                        onClick={this.downloadRsCSV}
                        style={{ height: '45px' }}
                    >
                        Download CSV
                    </Button>
                </div>
                <table className="table table-bordered">
                    <thead className="bg-primary text-white">
                        <tr>
                            <th>No</th>
                            <th>Logo</th>
                            <th>Club Name</th>
                            <th>Email</th>
                            <th>Count of Total Courts</th>
                            <th>Count of Courts Planned</th>
                            <th>Average Count of Courts Active</th>
                            <th>Count of Courts Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.searchData.length === 0 && (
                            <tr>
                                <td colSpan="8" className="text-danger text-center">
                                    No Data
                                </td>
                            </tr>
                        )}
                        {generateBody()}
                    </tbody>
                </table>
                {this.state.searchData.length > this.state.countPerPage && (
                    <div className="row">
                        <div className="col-sm-4 mt-3">
                            {`${
                                this.state.countPerPage * this.state.currentPage > this.state.searchData.length
                                    ? this.state.searchData.length
                                    : this.state.countPerPage * this.state.currentPage
                            }/${this.state.searchData.length}`}
                        </div>
                        <div className="col-sm-8">
                            <div className="pagination-content">
                                <Pagination
                                    activePage={this.state.currentPage}
                                    itemsCountPerPage={this.state.countPerPage}
                                    totalItemsCount={this.state.searchData.length}
                                    pageRangeDisplayed={3}
                                    onChange={this.changePaination}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser } = user;
    return { isLoader, authUser };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        userSignOutSuccess,
        userUpdateSuccess,
        handleHTTPError,
    })(CourtsReport)
);
