import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import PageContentLayout from 'components/PageContentLayout';
import { showLoader, showMessage, userSignOutSuccess, handleHTTPError } from 'store/actions';

import { getReport, resolveReport } from 'apis';

class ClubView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const params = {
            _id: this.props.match.params.id,
        };
        this.props.showLoader(true);
        getReport(params)
            .then((data) => {
                this.props.showLoader(false);
                this.setState({
                    report: data.data,
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    }

    resolveReportAction = () => {
        let params = {
            _id: this.state.report._id,
        };
        this.props.showLoader(true);
        resolveReport(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: data.message,
                    },
                });
                this.props.history.push('/issuesreport');
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        const { report } = this.state;
        if (!report) return null;

        return (
            <PageContentLayout title="Report Details">
                <p>
                    Reporter Name:
                    <span className="ml-2 font-weight-bold">{report.userName}</span>
                </p>
                <p>
                    Reporter Email:
                    <span className="ml-2 font-weight-bold">{report.email}</span>
                </p>
                <p>
                    Subject:
                    <span className="ml-2 font-weight-bold">{report.subject}</span>
                </p>
                <div className="border mb-2 p-3" style={{ borderRadius: '5px', minHeight: '300px' }}>
                    <h6>{report.message}</h6>
                </div>
                <div
                    className={`w-100 d-flex ${report.isResolved ? 'justify-content-end' : 'justify-content-between'}`}
                >
                    <Button
                        className="btn btn-outline-secondary btn-lg"
                        style={{ width: '200px' }}
                        onClick={() => {
                            this.props.history.push('/issuesreport');
                        }}
                    >
                        Back
                    </Button>
                    {!report.isResolved && (
                        <Button
                            color="secondary"
                            className="btn btn-secondary btn-lg"
                            style={{ width: '200px' }}
                            onClick={this.resolveReportAction}
                        >
                            Resolve
                        </Button>
                    )}
                </div>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui, settings }) => {
    const { authUser } = user;
    const { isLoader } = ui;
    return { authUser, isLoader };
};
export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        userSignOutSuccess,
        handleHTTPError,
    })(ClubView)
);
