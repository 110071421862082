import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import { readClubPaymentFeeSetting, updateClubPaymentFeeSetting } from 'apis';
import TextBox from 'components/controls/TextBox';
import PageContentLayout from 'components/PageContentLayout';

class ClubServiceFeeSettings extends Component {
    constructor(props) {
        super(props);

        const {
            cmtyEventPercentageFee,
            cmtyEventFixedFee,
            cmtyFreeEventFee,
            cmtyMembersEmailFee,
            cmtyRegMembersEmailFee,
            cmtyGeoAreaMembersEmailFee,
            cmtyAllMembersEmailFee,
            cmtyAdvocatesEmailFee,
        } = this.props.systemSettings;

        this.state = {
            cmtyEventPercentageFee,
            cmtyEventFixedFee,
            cmtyFreeEventFee,
            cmtyMembersEmailFee,
            cmtyRegMembersEmailFee,
            cmtyGeoAreaMembersEmailFee,
            cmtyAllMembersEmailFee,
            cmtyAdvocatesEmailFee,
            feeSetting: null,
            validate: false,
        };
    }

    componentDidMount = () => {
        this.props.showLoader(true);
        readClubPaymentFeeSetting({ club: this.props.club._id })
            .then((data) => {
                this.props.showLoader(false);
                if (data.data) {
                    this.setState({
                        feeSetting: data.data,
                        cmtyEventPercentageFee:
                            (data.data.cmtyEventPercentageFee ?? this.props.systemSettings.cmtyEventPercentageFee) *
                            100,
                        cmtyEventFixedFee: data.data.cmtyEventFixedFee ?? this.props.systemSettings.cmtyEventFixedFee,
                        cmtyFreeEventFee: data.data.cmtyFreeEventFee ?? this.props.systemSettings.cmtyFreeEventFee,
                        cmtyMembersEmailFee:
                            data.data.cmtyMembersEmailFee ?? this.props.systemSettings.cmtyMembersEmailFee,
                        cmtyRegMembersEmailFee:
                            data.data.cmtyRegMembersEmailFee ?? this.props.systemSettings.cmtyRegMembersEmailFee,
                        cmtyGeoAreaMembersEmailFee:
                            data.data.cmtyGeoAreaMembersEmailFee ??
                            this.props.systemSettings.cmtyGeoAreaMembersEmailFee,
                        cmtyAllMembersEmailFee:
                            data.data.cmtyAllMembersEmailFee ?? this.props.systemSettings.cmtyAllMembersEmailFee,
                        cmtyAdvocatesEmailFee:
                            data.data.cmtyAdvocatesEmailFee ?? this.props.systemSettings.cmtyAdvocatesEmailFee,
                    });
                }
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    update = () => {
        this.setState({ validate: true });
        const {
            cmtyEventPercentageFee,
            cmtyEventFixedFee,
            cmtyFreeEventFee,
            cmtyMembersEmailFee,
            cmtyRegMembersEmailFee,
            cmtyGeoAreaMembersEmailFee,
            cmtyAllMembersEmailFee,
            cmtyAdvocatesEmailFee,
        } = this.state;
        if (
            cmtyEventPercentageFee === '' ||
            cmtyEventFixedFee === '' ||
            cmtyFreeEventFee === '' ||
            cmtyMembersEmailFee === '' ||
            cmtyRegMembersEmailFee === '' ||
            cmtyGeoAreaMembersEmailFee === '' ||
            cmtyAllMembersEmailFee === '' ||
            cmtyAdvocatesEmailFee === ''
        )
            return;
        if (Number(cmtyEventPercentageFee) < 0 || Number(cmtyEventPercentageFee) >= 100) return;
        if (Number(cmtyGeoAreaMembersEmailFee) < 0.5 || Number(cmtyAllMembersEmailFee) < 0.5) return;

        let params = {
            cmtyEventPercentageFee: Number((Number(cmtyEventPercentageFee) / 100).toFixed(2)),
            cmtyEventFixedFee: Number(cmtyEventFixedFee),
            cmtyFreeEventFee: Number(Number(cmtyFreeEventFee).toFixed(2)),
            cmtyMembersEmailFee: Number(Number(cmtyMembersEmailFee).toFixed(2)),
            cmtyRegMembersEmailFee: Number(Number(cmtyRegMembersEmailFee).toFixed(2)),
            cmtyGeoAreaMembersEmailFee: Number(Number(cmtyGeoAreaMembersEmailFee).toFixed(2)),
            cmtyAllMembersEmailFee: Number(Number(cmtyAllMembersEmailFee).toFixed(2)),
            cmtyAdvocatesEmailFee: Number(Number(cmtyAdvocatesEmailFee).toFixed(2)),
        };
        if (this.state.feeSetting) params = { ...params, _id: this.state.feeSetting._id };
        else params = { ...params, club: this.props.club._id };
        this.props.showLoader(true);
        console.log(params);
        updateClubPaymentFeeSetting(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Club Fee Setting has been updated',
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        const { club } = this.props;
        if (!club) return null;

        return (
            <PageContentLayout title="Payment Fee Setting">
                <Row>
                    <Col md={6}>
                        <h2 className="font-weight-bold mb-4">Community Event Fee Setting</h2>
                        <Row className="mb-5">
                            <TextBox
                                label="Community Event Percentage Fee"
                                tabIndex="0"
                                id="cmtyEventPercentageFee"
                                name="cmtyEventPercentageFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyEventPercentageFee}
                                suffix="% of Total Revenue"
                                placeholder="15"
                                onBlur={() => {}}
                                onChange={(cmtyEventPercentageFee, e) => {
                                    this.setState({ cmtyEventPercentageFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'int',
                                    min: 0,
                                    max: 99,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="Community Event Fixed Fee"
                                tabIndex="0"
                                id="cmtyEventFixedFee"
                                name="cmtyEventFixedFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyEventFixedFee}
                                suffix="$ Per Event"
                                placeholder="10"
                                onBlur={() => {}}
                                onChange={(cmtyEventFixedFee, e) => {
                                    this.setState({ cmtyEventFixedFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'int',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="Community Event Free Event Fee"
                                tabIndex="0"
                                id="cmtyFreeEventFee"
                                name="cmtyFreeEventFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyFreeEventFee}
                                suffix="$ Per Free Event"
                                placeholder="1"
                                onBlur={() => {}}
                                onChange={(cmtyFreeEventFee, e) => {
                                    this.setState({ cmtyFreeEventFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <Col md={12} className="mt-2">
                                <p className="text-primary mb-0">
                                    If total revenue is $500 for 3 events, Application fee will be calculated like this.
                                    <br />3 * ${this.state.cmtyEventFixedFee} + $500 * ($
                                    {this.state.cmtyEventPercentageFee} / 100) = $
                                    {3 * Number(this.state.cmtyEventFixedFee) +
                                        (500 * Number(this.state.cmtyEventPercentageFee)) / 100}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <h2 className="font-weight-bold mb-4">Community Event Email Service Fee Setting</h2>
                        <Row className="mb-5">
                            <TextBox
                                label="For broadcasting to the members of organization"
                                tabIndex="0"
                                id="cmtyMembersEmailFee"
                                name="cmtyMembersEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyMembersEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyMembersEmailFee, e) => {
                                    this.setState({ cmtyMembersEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="For broadcasting to the registered member of event"
                                tabIndex="0"
                                id="cmtyRegMembersEmailFee"
                                name="cmtyRegMembersEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyRegMembersEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyRegMembersEmailFee, e) => {
                                    this.setState({ cmtyRegMembersEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="For broadcasting to the users in special geograpic area"
                                tabIndex="0"
                                id="cmtyGeoAreaMembersEmailFee"
                                name="cmtyGeoAreaMembersEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyGeoAreaMembersEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyGeoAreaMembersEmailFee, e) => {
                                    this.setState({ cmtyGeoAreaMembersEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0.5,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="For broadcasting to all users of network"
                                tabIndex="0"
                                id="cmtyAllMembersEmailFee"
                                name="cmtyAllMembersEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyAllMembersEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyAllMembersEmailFee, e) => {
                                    this.setState({ cmtyAllMembersEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0.5,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="For broadcasting to advocates of organization"
                                tabIndex="0"
                                id="cmtyAdvocatesEmailFee"
                                name="cmtyAdvocatesEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyAdvocatesEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyAdvocatesEmailFee, e) => {
                                    this.setState({ cmtyAdvocatesEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="d-flex justify-content-end">
                        <Button
                            color="secondary"
                            className="btn btn-lg mb-0"
                            style={{ width: '150px' }}
                            onClick={this.update}
                        >
                            update
                        </Button>
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser, systemSettings } = user;
    const { isLoader } = ui;
    return { authUser, isLoader, systemSettings };
};
export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(ClubServiceFeeSettings)
);
