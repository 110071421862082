// Ui
export const SHOW_FULLLOADER = 'show_fullloader';
export const SHOW_MESSAGE = 'show_message';
export const SHOW_LOADER = 'show_loader';
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const TOGGLE_SCHED_PANEL = 'toggle_sched_panel';
export const WINDOW_WIDTH = 'window-width';

export const HTTP_401_ERROR = 'http_unauthorized_error';
export const HTTP_OTHER_ERROR = 'http_other_error';

//user
export const USER_SIGNOUT_SUCCESS = 'signout_success';
export const USER_UPDATE_SUCCESS = 'user_update_success';

// members
export const SET_USER_SEARCHPARAMS = 'set_user_searchparams';

// settings
export const PUT_SYSTEM_SETTINGS = 'set_system_settings';
