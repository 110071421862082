import React, { Component } from 'react';
import { Badge, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from 'react-js-pagination';
import swal from 'sweetalert';
import CreateClubDlg from 'components/createclubdlg';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import { findClubsWithDetails, blockClub, activeClub, controlClubFeature } from 'apis';
import { Constants } from 'utils/constants';
import './index.scss';
import PageContentLayout from 'components/PageContentLayout';

class ClubsManage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalData: [],
            searchData: [],
            pageData: [],
            currentPage: 1,
            countPerPage: 10,
            searchWord: '',
            validate: false,
            showDefaultOption: false,
        };
    }

    componentDidMount = () => {
        this.findClubs();
    };

    findClubs = () => {
        this.props.showLoader(true);
        findClubsWithDetails({})
            .then((data) => {
                this.props.showLoader(false);
                const showDefaultOption = !data.data.some((club) => club.isDefault);
                const clubData = data.data;
                const newPageData = clubData.length > 0 ? clubData.slice(0, this.state.countPerPage) : [];
                this.setState({
                    totalData: clubData,
                    pageData: newPageData,
                    searchData: clubData,
                    currentPage: 1,
                    firstName: '',
                    lastName: '',
                    email: '',
                    isOpenCreateDlg: false,
                    showDefaultOption,
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    changePaination = (page) => {
        this.setState({
            currentPage: page,
            pageData: this.state.searchData.slice(this.state.countPerPage * (page - 1), this.state.countPerPage * page),
        });
    };

    search = () => {
        let newData = this.state.totalData;

        if (this.state.searchWord !== '') {
            newData = newData.filter(
                (data) =>
                    data.email.toLowerCase().indexOf(this.state.searchWord.toLowerCase()) > -1 ||
                    data.mobilePhone.indexOf(this.state.searchWord) > -1 ||
                    data.displayName.toLowerCase().indexOf(this.state.searchWord.toLowerCase()) > -1
            );
        }

        this.setState({
            searchData: newData,
            pageData: newData.slice(
                this.state.countPerPage * (this.state.currentPage - 1),
                this.state.countPerPage * this.state.currentPage
            ),
        });
    };

    blockClubAction = async (club) => {
        let ok = await swal({
            title: 'Confirm',
            text: 'Do you want to block this club?\n Are you sure?',
            icon: 'info',
            buttons: true,
            dangerMode: true,
        });
        if (!ok) return;

        let params = { _id: club._id };
        this.props.showLoader(true);
        blockClub(params)
            .then((data) => {
                club.status = Constants.CLUB_STATUS.BLOCKED;
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: `${club.displayName} has been blocked` },
                });
                this.props.showLoader(false);
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.props.showLoader(false);
            });
    };

    activeClubAction = async (club) => {
        let ok = await swal({
            title: 'Confirm',
            text: 'Do you want to active this club?\n Are you sure?',
            icon: 'info',
            buttons: true,
            dangerMode: true,
        });
        if (!ok) return;

        let params = { _id: club._id };
        this.props.showLoader(true);
        activeClub(params)
            .then((data) => {
                club.status = Constants.CLUB_STATUS.ACTIVATED;
                this.props.showMessage({
                    show: true,
                    message: { type: 'success', text: `${club.displayName} has been activated` },
                });
                this.props.showLoader(false);
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.props.showLoader(false);
            });
    };

    controlClubFeature = async (club, type, value) => {
        const ok = await swal({
            title: 'Warning',
            text: `Do you want to ${value ? 'active' : 'disable'} ${
                type === 'reservations' ? 'Full Reservations Service' : 'Comnunity Events'
            } of ${club.displayName}? \n Are you sure?`,
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) return;

        const params = {
            _id: club._id,
            allowReservations: type === 'reservations' ? value : undefined,
            allowCmtyEvent: type === 'cmtyevent' ? value : undefined,
            type,
        };
        this.props.showLoader(true);
        controlClubFeature(params)
            .then((data) => {
                if (type === 'reservations') club.allowReservations = value;
                else if (type === 'cmtyevent') club.allowCmtyEvent = value;
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: data.message,
                    },
                });
                this.props.showLoader(false);
            })
            .catch((error) => {
                this.props.handleHTTPError(error);
                this.props.showLoader(false);
            });
    };

    toggleCreateDlg = () => {
        this.setState({ isOpenCreateDlg: !this.state.isOpenCreateDlg });
    };

    updateClubs = () => {
        this.findClubs();
    };

    render() {
        const generateBody = () => {
            return this.state.pageData.map((club, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{club.ID}</td>
                        <td className="text-center">
                            <img
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '100%',
                                    border: '1px solid #ccc',
                                }}
                                src={club.logoUrl || Constants.DEFAULT_LOGO}
                                alt="logo"
                            />
                        </td>
                        <td>
                            {club.displayName}{' '}
                            {club.status === Constants.CLUB_STATUS.INACTIVATED && <Badge color="success">New</Badge>}
                            {club.approveRsService === Constants.RSFULLSERVICE_APPROVE_STEP.REQUEST && (
                                <Badge color="primary">Request</Badge>
                            )}
                        </td>
                        <td>{club.email}</td>
                        <td>{club.mobilePhone}</td>
                        <td>
                            {!club.isDefault && (
                                <>
                                    <Button
                                        color={club.allowReservations ? 'warning' : 'primary'}
                                        size="sm"
                                        className="d-block"
                                        onClick={() => {
                                            this.controlClubFeature(club, 'reservations', !club.allowReservations);
                                        }}
                                    >
                                        {club.allowReservations ? 'Disable' : 'Activate'} Full Reservations Service
                                    </Button>
                                    <Button
                                        color={club.allowCmtyEvent ? 'warning' : 'primary'}
                                        size="sm"
                                        onClick={() => {
                                            this.controlClubFeature(club, 'cmtyevent', !club.allowCmtyEvent);
                                        }}
                                    >
                                        {club.allowCmtyEvent ? 'Disable' : 'Activate'} Community Events
                                    </Button>
                                </>
                            )}
                        </td>
                        <td>
                            <div className="d-flex justify-content-around">
                                <div
                                    className="text-gray pointer view"
                                    onClick={() => {
                                        this.props.history.push(`${this.props.match.url}/${club._id}`);
                                    }}
                                >
                                    <FontAwesomeIcon icon="search" />
                                    &nbsp;&nbsp;View
                                </div>
                                {club.status === Constants.CLUB_STATUS.ACTIVATED && (
                                    <div
                                        className="text-secondary pointer"
                                        onClick={() => {
                                            this.blockClubAction(club);
                                        }}
                                    >
                                        <FontAwesomeIcon icon="ban" />
                                        &nbsp;&nbsp;Block
                                    </div>
                                )}
                                {(club.status === Constants.CLUB_STATUS.BLOCKED ||
                                    club.status === Constants.CLUB_STATUS.INACTIVATED) && (
                                    <div
                                        className="text-primary pointer"
                                        onClick={() => {
                                            this.activeClubAction(club);
                                        }}
                                    >
                                        <FontAwesomeIcon icon="check" />
                                        &nbsp;&nbsp;Active
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                );
            });
        };

        return (
            <PageContentLayout
                header={
                    <div className="d-flex justify-content-between align-items-center">
                        <h1 className="font-weight-bold">Clubs</h1>
                        <Button
                            color="secondary"
                            className="btn btn-secondary btn-lg btn-block"
                            onClick={this.toggleCreateDlg}
                            style={{ width: '200px' }}
                        >
                            <FontAwesomeIcon icon="plus" className="mr-2" />
                            Create New Club
                        </Button>
                    </div>
                }
            >
                <table className="table table-bordered club-list-table">
                    <thead className="bg-primary text-white">
                        <tr>
                            <th>No</th>
                            <th>ID</th>
                            <th>Logo</th>
                            <th>Club Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Allowed Features</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.searchData.length === 0 && (
                            <tr>
                                <td colSpan="7" className="text-danger text-center">
                                    No Data
                                </td>
                            </tr>
                        )}
                        {generateBody()}
                    </tbody>
                </table>
                {this.state.searchData.length > this.state.countPerPage && (
                    <div className="row">
                        <div className="col-sm-4 mt-3">
                            {`${
                                this.state.countPerPage * this.state.currentPage > this.state.searchData.length
                                    ? this.state.searchData.length
                                    : this.state.countPerPage * this.state.currentPage
                            }/${this.state.searchData.length}`}
                        </div>
                        <div className="col-sm-8">
                            <div className="pagination-content">
                                <Pagination
                                    activePage={this.state.currentPage}
                                    itemsCountPerPage={this.state.countPerPage}
                                    totalItemsCount={this.state.searchData.length}
                                    pageRangeDisplayed={3}
                                    onChange={this.changePaination}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <CreateClubDlg
                    isOpen={this.state.isOpenCreateDlg}
                    toggle={this.toggleCreateDlg}
                    showDefaultOption={this.state.showDefaultOption}
                    updateClubs={this.updateClubs}
                />
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser } = user;
    return { authUser, isLoader };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(ClubsManage)
);
