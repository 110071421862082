import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import TextBox from 'components/controls/TextBox';
import TextArea from 'components/controls/TextArea';
import { Constants } from 'utils/constants';

class UserInvoiceDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoice: null,
            events: [],
        };
    }

    componentDidMount = () => {};

    render() {
        if (!this.props.invoice) return null;

        return (
            <>
                <div className="d-flex justify-content-between">
                    <h2 className="font-weight-bold mb-3 btn-link pointer">
                        Invoice of {this.props.invoice.user?.fullName}
                    </h2>
                    <h2 className="font-weight-bold mr-2">{this.props.invoice.invoiceNumber}</h2>
                </div>
                <Row className="mb-5">
                    <Col md={12} className="mb-3">
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }} className="border">
                            <table className="table table-bordered mb-0" style={{ minWidth: '800px' }}>
                                <thead className="bg-primary text-white">
                                    <tr>
                                        <th>No</th>
                                        <th>Date</th>
                                        <th>Title</th>
                                        <th>Free Event</th>
                                        <th>Donate</th>
                                        <th>Attendees</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.invoice.invoiceItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{moment(item.event.start).format('MMM DD YYYY h:mm A')}</td>
                                            <td>{item.event.title}</td>
                                            <td>{item.event?.isFree ? 'Yes' : 'No'}</td>
                                            <td>{item.event?.isFree ? `$ ${item.subTotal.toFixed(2)}` : ''}</td>
                                            <td>{item.event?.isFree ? '' : item.attendees}</td>
                                            <td>{item.event?.isFree ? '' : `$ ${item.subTotal.toFixed(2)}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col md={6}>
                        <h4 className="font-weight-bold">Preferred Payment Service</h4>
                        <p className="text-danger">
                            {this.props.invoice.club?.stripeId &&
                            this.props.invoice.club?.preferPaymentService === 'stripe'
                                ? 'Club set up Stripe payout and prefers Stripe payment. If you want to pay via other payment services(Paypal, Bank, etc), Please contact to club.'
                                : 'Club wants to get a payment transfer via other services (Paypal, Bank, etc) except Stripe. Please contact to club.'}
                        </p>
                        <h4 className="font-weight-bold">Application Fee of Club</h4>
                        <p>
                            {this.props.invoice.rates.percentageRate * 100}% of{' '}
                            <span className=" font-weight-bold">Total Revenue (If event price over $10)</span>, $
                            {this.props.invoice.rates.fixedRate} <span className="font-weight-bold">Per an Event</span>,
                        </p>
                    </Col>
                    <Col md={3}>
                        <TextBox
                            label="Discount"
                            id="discount"
                            name="discount"
                            className="mb-1"
                            type="text"
                            disabled={this.props.invoice.status === Constants.INVOICE_STATUS.PAID}
                            value={this.props.discount || 0}
                            placeholder="25.36"
                            prefix="$"
                            suffix="USD"
                            onChange={(discount) => {
                                this.props.updateDiscount(Number(discount));
                            }}
                            validationOption={{
                                showMsg: false,
                                type: 'number',
                                numberType: 'decimal',
                                check: true,
                                required: true,
                            }}
                        />
                        <TextArea
                            id="note"
                            name="note"
                            type="text"
                            rows={3}
                            disabled={this.props.invoice.status === Constants.INVOICE_STATUS.PAID}
                            value={this.props.note || ''}
                            placeholder="Note here for invocie"
                            onChange={(note) => {
                                this.props.updateNote(note);
                            }}
                            validationOption={{
                                showMsg: false,
                                check: true,
                                required: true,
                            }}
                        />
                    </Col>
                    <Col md={3} className="d-flex justify-content-end align-items-center">
                        <div className="text-right mr-5">
                            <p className="mb-1">Sub Total:</p>
                            <p className="mb-1">Stripe Fee:</p>
                            <p className="mb-1">Community Fee:</p>
                            <p className="mb-1">Discount:</p>
                            <p className="mb-1">Total:</p>
                        </div>
                        <div className="text-right font-weight-bold">
                            <p className="mb-1">${this.props.invoice.subTotal.toFixed(2)}</p>
                            <p className="mb-1">-${this.props.invoice.stripeFee.toFixed(2)}</p>
                            <p className="mb-1">-${this.props.invoice.applicationFee.toFixed(2)}</p>
                            <p className="mb-1">-${this.props.discount.toFixed(2)}</p>
                            <p className="mb-1">
                                $
                                {(
                                    this.props.invoice.subTotal -
                                    this.props.invoice.stripeFee -
                                    this.props.invoice.applicationFee -
                                    Number(this.props.discount)
                                ).toFixed(2)}
                            </p>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser } = user;
    return { authUser };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(UserInvoiceDetails)
);
