import React, { Component } from 'react'
import {Button} from 'reactstrap'
import { connect } from 'react-redux';
import TextBox from 'components/controls/TextBox'
import CircularProgress from 'components/CircularProgress'
import {
    showLoader,
    showMessage,
    handleHTTPError
} from 'store/actions';
import {
    resetPassword
} from 'apis';
import './index.scss'


class ResetPass extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            newPassword: "",
            confirmPassword: "",
            email: null,
            token: null,
            validate: false,
            showMsg: false,
        }
        this.resetPass = this.resetPass.bind(this)
    }

    componentDidMount(){
        if (!this.props.match.params.email || !this.props.match.params.token){
            this.props.showMessage({
                show: true,
                message: {
                    text: "Invalid url",
                    type: 'error'
                }
            })
            this.props.history.push('/')
            return;
        }
        this.setState({
            email: this.props.match.params.email,
            token: this.props.match.params.token
        })
    }
    
    resetPass(){
        this.setState({validate: true});
        if (this.state.newPassword === ''){
            return;
        }
        if(this.state.newPassword !== this.state.confirmPassword){
            this.props.showMessage({
                show: true,
                message: {
                    text: "Password is not matched",
                    type: 'warning'
                }
            })
            return;
        }
        this.props.showLoader(true);
        resetPassword({
            password: this.state.newPassword,
            email: this.state.email,
            token: this.state.token
        }).then(data => {
            this.props.showLoader(false)
            this.props.showMessage({
                show: true,
                message: {
                    text: data.message,
                    type: 'success'
                }
            })
            this.props.history.push('/signin')
        }).catch(error => {
            this.props.showLoader(false)
            this.props.handleHTTPError(error)
        })

    }

    render() {
        const { isLoader } = this.props;
        return (
            <div  className="resetpass-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 offset-sm-0 content border shadow">
                            <div className="logo text-center">
                                <img src="/images/logo/logo_green.png" alt="" style={{maxWidth: '250px'}}/>
                                <h2 className="my-4 font-weight-bold">Reset Password</h2>
                            </div>
                            <div className="form-group mb-4 text-left">
                                <label htmlFor="email">New Password</label>
                                <TextBox tabIndex="0" id="newPassword" name="newPassword" type='password' 
                                    validate = {this.state.validate} value={this.state.newPassword} placeholder="New Password"
                                    onBlur={(e)=>{}}
                                    onChange={(newPassword, e) => {
                                        this.setState({
                                            newPassword: newPassword
                                        })
                                    }}
                                    validationOption={{
                                        name: 'New Password',
                                        check: true,
                                        required: true, 
                                    }}
                                />
                            </div>
                            <div className="form-group mb-4 text-left">
                                <label htmlFor="email">Confirm Password</label>
                                <TextBox tabIndex="0" id="confirmPassword" name="confirmPassword" type='password' 
                                    validate = {this.state.validate} value={this.state.confirmPassword} placeholder="Confrim Password"
                                    onBlur={(e)=>{}}
                                    onChange={(confirmPassword, e) => {
                                        this.setState({
                                            confirmPassword: confirmPassword
                                        })
                                    }}
                                    validationOption={{
                                        name: 'Confrim Password',
                                        check: true,
                                        required: true, 
                                    }}
                                />
                            </div>
                            <div className="form-group mt-5">
                                <Button color="primary" className="btn btn-primary btn-lg btn-block" onClick={this.resetPass}>Reset Password</Button>
                            </div>
                            <p className="text-primary linkbtn text-center mt-4" onClick={()=>{
                                this.props.history.push('/')
                            }}>Try login again</p>
                            {
                                isLoader &&
                                <div className="loader-view">
                                    <CircularProgress />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser } = user;
    const { isLoader} = ui;
    return { isLoader, authUser }
};

export default connect (mapStateToProps, {
    showLoader,
    showMessage,
    handleHTTPError
}) (ResetPass);

