import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from 'react-js-pagination';
import { showLoader, showMessage, userSignOutSuccess, handleHTTPError } from 'store/actions';
import { searchReports } from 'apis';
import moment from 'moment';
import PageContentLayout from 'components/PageContentLayout';

class IssueList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalData: [],
            searchData: [],
            pageData: [],
            currentPage: 1,
            countPerPage: 10,
        };
    }

    componentDidMount = () => {
        this.searchReports();
    };

    searchReports = () => {
        this.props.showLoader(true);
        searchReports({})
            .then((data) => {
                this.props.showLoader(false);
                const newPageData = data.data.length > 0 ? data.data.slice(0, this.state.countPerPage) : [];
                this.setState({
                    totalData: data.data,
                    pageData: newPageData,
                    searchData: data.data,
                    currentPage: 1,
                    firstName: '',
                    lastName: '',
                    email: '',
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    changePaination = (page) => {
        this.setState({
            currentPage: page,
            pageData: this.state.searchData.slice(this.state.countPerPage * (page - 1), this.state.countPerPage * page),
        });
    };

    render() {
        const generateBody = () => {
            return this.state.pageData.map((report, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{moment(report.createdAt).format('MMM DD, YYYY HH:mm:ss A')}</td>
                        <td>{report.email}</td>
                        <td>{report.subject}</td>
                        <td>{report.message.slice(0, 30) + '...'}</td>
                        <td className=" text-capitalize">{report.reportType}</td>
                        <td className={!report.isResolved ? 'text-danger' : 'text-primary'}>
                            {report.isResolved ? 'Resolved' : 'Unresolved'}
                        </td>
                        <td className="d-flex justify-content-center">
                            <div
                                className="text-gray pointer view"
                                onClick={() => {
                                    this.props.history.push(`${this.props.match.url}/${report._id}`);
                                }}
                            >
                                <FontAwesomeIcon icon="search" />
                                &nbsp;&nbsp;View
                            </div>
                        </td>
                    </tr>
                );
            });
        };

        return (
            <PageContentLayout title="Issues">
                <table className="table table-bordered">
                    <thead className="bg-primary text-white">
                        <tr>
                            <th>No</th>
                            <th>Created Date</th>
                            <th>Reporter</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.searchData.length === 0 && (
                            <tr>
                                <td colSpan="8" className="text-danger text-center">
                                    No Data
                                </td>
                            </tr>
                        )}
                        {generateBody()}
                    </tbody>
                </table>
                {this.state.searchData.length > this.state.countPerPage && (
                    <div className="row">
                        <div className="col-sm-4 mt-3">
                            {`${
                                this.state.countPerPage * this.state.currentPage > this.state.searchData.length
                                    ? this.state.searchData.length
                                    : this.state.countPerPage * this.state.currentPage
                            }/${this.state.searchData.length}`}
                        </div>
                        <div className="col-sm-8">
                            <div className="pagination-content">
                                <Pagination
                                    activePage={this.state.currentPage}
                                    itemsCountPerPage={this.state.countPerPage}
                                    totalItemsCount={this.state.searchData.length}
                                    pageRangeDisplayed={3}
                                    onChange={this.changePaination}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser } = user;
    return { isLoader, authUser };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        userSignOutSuccess,
        handleHTTPError,
    })(IssueList)
);
