import store from 'store'
import {
    SHOW_MESSAGE,
    TOGGLE_COLLAPSED_NAV,
    WINDOW_WIDTH,
    SHOW_FULLLOADER,
    SHOW_LOADER,
    TOGGLE_SCHED_PANEL,
    HTTP_401_ERROR,
    HTTP_OTHER_ERROR
} from '../actionTypes';

const INIT_STATE = {
    isFullLoader: false,
    isLoader: false,
    showSchedPanel: false,
    navCollapsed: false,
    width: window.innerWidth,
    message: {
        text: '',
        type: 'success'
    },
    showMsg: false,
};


export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case SHOW_FULLLOADER: {
            return {
                ...state,
                isFullLoader: action.payload
            }
        }

        case SHOW_LOADER: {
            return {
                ...state,
                isLoader: action.payload
            }
        }

        case TOGGLE_COLLAPSED_NAV:{
            return {
                ...state,
                navCollapsed: action.payload
            };
        }

        case TOGGLE_SCHED_PANEL:{
            return {
                ...state,
                showSchedPanel: action.payload
            };
        }

        case WINDOW_WIDTH: {
            return {
                ...state,
                width: action.payload
            };
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                message: action.payload.message ? action.payload.message : {type: 'success', text: ''},
                showMsg: action.payload.show,
            }
        }

        case HTTP_401_ERROR: {
            store.clearAll();
            window.location.href = "/signin"
            return { ...state };
        }

        case HTTP_OTHER_ERROR: {
            return { 
                ...state, 
                showMsg: true, 
                message: { type: action.payload.status < 400 ? 'warning': 'error', text: action.payload.data ? action.payload.data.message || 'Unknow Error' : String(action.payload) } }
        }

        default:
            return state;
    }
}
