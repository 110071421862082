import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { showLoader, showMessage, handleHTTPError, putSystemSettings } from 'store/actions';
import { updateSysManage } from 'apis';
import TextBox from 'components/controls/TextBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class VenueSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venues: [],
            validate: false,
        };
        this.newVenueRef = React.createRef();
    }

    componentDidMount = () => {
        let venues = this.props.systemSettings.cmtyVenues || [];
        if (venues.length === 0) venues = [''];
        this.setState({ venues });
    };

    update = () => {
        this.setState({ validate: true });

        // check empty venue
        if (this.state.venues.some((venue) => !venue)) return;

        // check duplicated venue
        let dupVenue = this.state.venues.find((venue, index, self) => self.indexOf(venue) !== index);
        if (dupVenue)
            this.props.showMessage({
                show: true,
                message: { type: 'warning', text: `Duplicated venue "${dupVenue}"` },
            });

        const params = {
            _id: this.props.systemSettings._id,
            cmtyVenues: this.state.venues.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)),
        };
        this.props.showLoader(true);
        updateSysManage(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.putSystemSettings(data.data);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Community event venues has been updated.',
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    add = () => {
        const venues = [...this.state.venues, ''];
        this.setState({ validate: false, venues });
        setTimeout(() => {
            this.newVenueRef.current.scroll({ top: this.newVenueRef.current.scrollHeight, behavior: 'smooth' });
        }, 300);
    };

    delete = async (index) => {
        const venues = [...this.state.venues];
        const ok = await swal({
            title: 'Warning',
            text: `Do you want to remove this venue? \n Are you sure?`,
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) {
            return;
        }
        venues.splice(index, 1);
        this.setState({ venues });
    };

    render() {
        const displayVenues = () => {
            return this.state.venues.map((venue, index) => {
                return (
                    <div key={index} className="mb-3 d-flex">
                        <TextBox
                            id={`prefix${index}`}
                            name={`prefix${index}`}
                            type="text"
                            className="mr-5"
                            onBlur={() => {}}
                            validate={this.state.validate}
                            value={venue || ''}
                            placeholder="Sports-Racket"
                            onChange={(name, e) => {
                                const venues = [...this.state.venues];
                                venues[index] = name;
                                this.setState({ venues });
                            }}
                            validationOption={{
                                required: true,
                                check: true,
                                showMsg: false,
                            }}
                        />
                        <div
                            className="text-secondary h1 d-flex justify-content-center align-items-end mb-2 pointer w-25"
                            onClick={() => {
                                this.delete(index);
                            }}
                        >
                            <FontAwesomeIcon icon="times" />
                        </div>
                    </div>
                );
            });
        };

        return (
            <>
                <h2 className="mb-4 font-weight-bold text-center">Community Venues</h2>
                <div
                    style={{ maxHeight: '500px', overflowY: 'auto' }}
                    className="border py-3 pl-3 mb-4"
                    ref={this.newVenueRef}
                >
                    {displayVenues()}
                </div>
                <Row>
                    <Col md={6}>
                        <Button color="primary" className="btn btn-lg btn-block" onClick={this.add}>
                            <FontAwesomeIcon icon="plus" className="mr-3" />
                            Add New Venue
                        </Button>
                    </Col>
                    <Col md={6}>
                        <Button color="secondary" className="btn btn-lg btn-block" onClick={this.update}>
                            <FontAwesomeIcon icon="save" className="mr-3" />
                            Save
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser, systemSettings } = user;
    return { authUser, systemSettings };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
        putSystemSettings,
    })(VenueSettings)
);
