import React from 'react'
import { connect } from 'react-redux';
import CircularProgress from 'components/CircularProgress';
import './index.scss'

class RouteContentLayout extends React.Component {
    render() {
        return (
            <div id="route-content">
                { this.props.children }
                {
                    this.props.isLoader &&
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ ui }) => {

    const { isLoader } = ui;

    return { isLoader }
};

export default connect(mapStateToProps, {})(RouteContentLayout);