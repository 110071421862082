import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showLoader, showMessage, setMemberSearchParams, handleHTTPError } from 'store/actions';
import ComplexSelect from 'components/controls/ComplexSelect';
import { retrieveInvoices, retrieveClubs } from 'apis';
import { Constants } from 'utils/constants';
import PageContentLayout from 'components/PageContentLayout';

class ClubInvoiceList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultClub: null,
            totalCount: 0,
            invoices: [],
            clubs: [],
            currentPage: 1,
            countPerPage: 10,
            club: '',
            status: 'all',
        };
    }

    componentDidMount = () => {
        this.searchClubs();
    };

    changePaination = (page) => {
        this.setState(
            {
                currentPage: page,
            },
            () => this.search((page - 1) * this.state.countPerPage)
        );
    };

    searchClubs = () => {
        const params = {
            isDefault: false,
            status: Constants.CLUB_STATUS.ACTIVATED,
            allowCmtyEvent: true,
        };
        retrieveClubs(params).then((data) => {
            this.setState({ clubs: data.data, club: data.data[0]._id }, this.search);
        });
    };

    search = (skip = 0) => {
        const params = {
            club: this.state.club || undefined,
            skip: skip || 0,
            limit: this.state.countPerPage,
            sort_by: 'createdAt',
            order_by: 'desc',
        };

        this.props.showLoader(true);
        retrieveInvoices(params)
            .then((data) => {
                this.props.showLoader(false);
                this.setState({
                    invoices: data.data,
                    totalCount: data.totalCount,
                    currentPage: params.skip / this.state.countPerPage + 1,
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        const generateBody = () => {
            if (this.state.invoices.length === 0) {
                return (
                    <tr>
                        <td colSpan="8" className="text-danger text-center">
                            No Invoices
                        </td>
                    </tr>
                );
            }
            return this.state.invoices.map((invoice, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{invoice.invoiceNumber}</td>
                        <td
                            className="btn-link pointer"
                            onClick={() => {
                                this.props.history.push(`/clubs/${invoice.club._id}`);
                            }}
                        >
                            {invoice.club.displayName}
                        </td>
                        <td>{invoice.invoiceItems.length}</td>
                        <td>${invoice.total.toFixed(2)}</td>
                        <td>{moment(invoice.createdDate).format('DD, MM YYYY')}</td>
                        <td>{moment(invoice.dueDate).format('DD, MM YYYY')}</td>
                        <td className="font-weight-bold">
                            {invoice.paidDate ? moment(invoice.paidDate).format('DD, MM YYYY') : ''}
                        </td>
                        <td
                            className={`${
                                invoice.status === Constants.INVOICE_STATUS.PAID ? 'text-success' : 'text-warning'
                            } text-capitalize font-weight-bold`}
                        >
                            {invoice.status === Constants.INVOICE_STATUS.CREATED ? 'Unpaid' : invoice.status}
                        </td>
                        <td className="d-flex align-items-center">
                            <div
                                className="px-4 text-primary pointer btn-link"
                                onClick={() => {
                                    this.props.history.push(`/club-invoices/${invoice._id}`);
                                }}
                            >
                                <FontAwesomeIcon icon="search" className="mr-2" /> Details
                            </div>
                        </td>
                    </tr>
                );
            });
        };

        const getClubValue = () => {
            const club = this.state.clubs.find((c) => c._id === this.state.club);
            if (club) return { value: club._id, label: club.displayName };
            return null;
        };

        const generateClubOptions = () => {
            return this.state.clubs.map((club) => {
                return { value: club._id, label: club.displayName };
            });
        };

        if (this.state.clubs.length === 0) return null;

        return (
            <PageContentLayout title="Invoices">
                <Row>
                    <ComplexSelect
                        className="col-md-3 mb-3"
                        placeholder="All"
                        label="Club"
                        value={getClubValue()}
                        options={generateClubOptions()}
                        onChange={(club) => {
                            this.setState({ club: club?.value }, this.search);
                        }}
                    />
                    <Col md={12}>
                        <div className="table-wrapper">
                            <table className="table table-bordered">
                                <thead className="bg-primary text-white">
                                    <tr>
                                        <th>No</th>
                                        <th>Invoice Number</th>
                                        <th>Club</th>
                                        <th>Events Count</th>
                                        <th>Price</th>
                                        <th>Issued Date</th>
                                        <th>Due Date</th>
                                        <th>Paid Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{generateBody()}</tbody>
                            </table>
                        </div>
                        {this.state.totalCount > this.state.countPerPage && (
                            <div className="row">
                                <div className="col-sm-4 mt-3">
                                    {`${
                                        this.state.countPerPage * this.state.currentPage > this.state.totalCount
                                            ? this.state.totalCount
                                            : this.state.countPerPage * this.state.currentPage
                                    }/${this.state.totalCount}`}
                                </div>
                                <div className="col-sm-8">
                                    <div className="pagination-content">
                                        <Pagination
                                            activePage={this.state.currentPage}
                                            itemsCountPerPage={this.state.countPerPage}
                                            totalItemsCount={this.state.totalCount}
                                            pageRangeDisplayed={3}
                                            onChange={this.changePaination}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser, memberSearchParams } = user;
    return { isLoader, authUser, memberSearchParams };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        setMemberSearchParams,
        handleHTTPError,
    })(ClubInvoiceList)
);
