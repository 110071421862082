import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { showLoader, showMessage, handleHTTPError, putSystemSettings } from 'store/actions';
import { updateSysManage } from 'apis';
import TextBox from 'components/controls/TextBox';
import PageContentLayout from 'components/PageContentLayout';

class ServiceFeeSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cmtyEventPercentageFee: 15,
            cmtyEventFixedFee: 10,
            cmtyEventIdeaFee: 1,
            cmtyFreeEventFee: 0.5,
            cmtyEventAdvocateFixedFee: 1,
            cmtyEventAdvocatePercentageFee: 0,
            cmtyMembersEmailFee: 0.5,
            cmtyRegMembersEmailFee: 0.5,
            cmtyGeoAreaMembersEmailFee: 0.5,
            cmtyAllMembersEmailFee: 2.0,
            cmtyAdvocatesEmailFee: 0,
            validate: false,
        };
    }

    componentDidMount = () => {
        this.setState({
            cmtyEventPercentageFee: (this.props.systemSettings.cmtyEventPercentageFee || 0.15) * 100,
            cmtyEventFixedFee: this.props.systemSettings.cmtyEventFixedFee || 10,
            cmtyEventIdeaFee: this.props.systemSettings.cmtyEventIdeaFee || 1,
            cmtyFreeEventFee: this.props.systemSettings.cmtyFreeEventFee || 0.5,
            cmtyEventAdvocateFixedFee: this.props.systemSettings.cmtyEventAdvocateFixedFee || 1,
            cmtyEventAdvocatePercentageFee: (this.props.systemSettings.cmtyEventAdvocatePercentageFee || 0) * 100,
            cmtyMembersEmailFee: this.props.systemSettings.cmtyMembersEmailFee || 0.5,
            cmtyRegMembersEmailFee: this.props.systemSettings.cmtyRegMembersEmailFee || 0.5,
            cmtyGeoAreaMembersEmailFee: this.props.systemSettings.cmtyGeoAreaMembersEmailFee || 0.5,
            cmtyAllMembersEmailFee: this.props.systemSettings.cmtyAllMembersEmailFee || 2.0,
            cmtyAdvocatesEmailFee: this.props.systemSettings.cmtyAdvocatesEmailFee || 0,
        });
    };

    update = () => {
        this.setState({ validate: true });
        const {
            cmtyEventPercentageFee,
            cmtyEventFixedFee,
            cmtyEventIdeaFee,
            cmtyFreeEventFee,
            cmtyEventAdvocateFixedFee,
            cmtyEventAdvocatePercentageFee,
            cmtyMembersEmailFee,
            cmtyRegMembersEmailFee,
            cmtyGeoAreaMembersEmailFee,
            cmtyAllMembersEmailFee,
            cmtyAdvocatesEmailFee,
        } = this.state;
        if (
            cmtyEventPercentageFee === '' ||
            cmtyEventFixedFee === '' ||
            cmtyEventIdeaFee === '' ||
            cmtyFreeEventFee === '' ||
            cmtyEventAdvocateFixedFee === '' ||
            cmtyEventAdvocatePercentageFee === '' ||
            cmtyMembersEmailFee === '' ||
            cmtyRegMembersEmailFee === '' ||
            cmtyGeoAreaMembersEmailFee === '' ||
            cmtyAllMembersEmailFee === '' ||
            cmtyAdvocatesEmailFee === ''
        )
            return;
        if (Number(cmtyEventPercentageFee) < 0 || Number(cmtyEventPercentageFee) >= 100) return;
        if (Number(cmtyEventAdvocatePercentageFee) < 0 || Number(cmtyEventAdvocatePercentageFee) >= 100) return;
        if (Number(cmtyGeoAreaMembersEmailFee) < 0.5 || Number(cmtyAllMembersEmailFee) < 0.5) return;

        const params = {
            _id: this.props.systemSettings._id,
            cmtyEventPercentageFee: Number((Number(cmtyEventPercentageFee) / 100).toFixed(2)),
            cmtyEventFixedFee: Number(cmtyEventFixedFee),
            cmtyEventIdeaFee: Number(Number(cmtyEventIdeaFee).toFixed(2)),
            cmtyFreeEventFee: Number(Number(cmtyFreeEventFee).toFixed(2)),
            cmtyEventAdvocateFixedFee: Number(Number(cmtyEventAdvocateFixedFee).toFixed(2)),
            cmtyEventAdvocatePercentageFee: Number((Number(cmtyEventAdvocatePercentageFee) / 100).toFixed(2)),
            cmtyMembersEmailFee: Number(Number(cmtyMembersEmailFee).toFixed(2)),
            cmtyRegMembersEmailFee: Number(Number(cmtyRegMembersEmailFee).toFixed(2)),
            cmtyGeoAreaMembersEmailFee: Number(Number(cmtyGeoAreaMembersEmailFee).toFixed(2)),
            cmtyAllMembersEmailFee: Number(Number(cmtyAllMembersEmailFee).toFixed(2)),
            cmtyAdvocatesEmailFee: Number(Number(cmtyAdvocatesEmailFee).toFixed(2)),
        };
        this.props.showLoader(true);
        updateSysManage(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.putSystemSettings(data.data);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Setting has been updated',
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        return (
            <PageContentLayout title="Service Default Fee Settings">
                <Row>
                    <Col md={6}>
                        <h2 className="font-weight-bold mb-4">Community Event Fee Setting</h2>
                        <Row className="mb-5">
                            <TextBox
                                label="Community Event Default Percentage Fee"
                                tabIndex="0"
                                id="cmtyEventPercentageFee"
                                name="cmtyEventPercentageFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyEventPercentageFee}
                                suffix="% of Total Revenue"
                                placeholder="15"
                                onBlur={() => {}}
                                onChange={(cmtyEventPercentageFee, e) => {
                                    this.setState({ cmtyEventPercentageFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'int',
                                    min: 0,
                                    max: 99,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="Community Event Default Fixed Fee"
                                tabIndex="0"
                                id="cmtyEventFixedFee"
                                name="cmtyEventFixedFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyEventFixedFee}
                                suffix="$ Per Event"
                                placeholder="10"
                                onBlur={() => {}}
                                onChange={(cmtyEventFixedFee, e) => {
                                    this.setState({ cmtyEventFixedFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'int',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="Community Event Free Event Default Fee"
                                tabIndex="0"
                                id="cmtyFreeEventFee"
                                name="cmtyFreeEventFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyFreeEventFee}
                                suffix="$ Per Free Event"
                                placeholder="1"
                                onBlur={() => {}}
                                onChange={(cmtyFreeEventFee, e) => {
                                    this.setState({ cmtyFreeEventFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <Col md={12} className="mt-2">
                                <p className="text-primary mb-0">
                                    If total revenue is $500 for 3 events, Application fee will be calculated like this.
                                    <br />3 * ${this.state.cmtyEventFixedFee} + $500 * ($
                                    {this.state.cmtyEventPercentageFee} / 100) = $
                                    {3 * Number(this.state.cmtyEventFixedFee) +
                                        (500 * Number(this.state.cmtyEventPercentageFee)) / 100}
                                </p>
                            </Col>
                        </Row>
                        <h2 className="font-weight-bold mb-4">Community Event Idea Fee Setting</h2>
                        <TextBox
                            label="Community Event Idea Post Fee"
                            tabIndex="0"
                            id="cmtyEventFixedFee"
                            name="cmtyEventFixedFee"
                            className="mb-5"
                            type="text"
                            validate={this.state.validate}
                            value={this.state.cmtyEventIdeaFee}
                            suffix="$ Per Idea"
                            placeholder="1"
                            onBlur={() => {}}
                            onChange={(cmtyEventIdeaFee, e) => {
                                this.setState({ cmtyEventIdeaFee });
                            }}
                            validationOption={{
                                type: 'number',
                                numberType: 'decimal',
                                min: 0,
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                        <h2 className="font-weight-bold mb-4">Community Event Advocate User Fee Settings</h2>
                        <TextBox
                            label="Advocate User Fixed Fee"
                            tabIndex="0"
                            id="cmtyEventAdvocateFixedFee"
                            name="cmtyEventAdvocateFixedFee"
                            type="text"
                            className="mb-3"
                            validate={this.state.validate}
                            value={this.state.cmtyEventAdvocateFixedFee}
                            suffix="$ Per Registration"
                            placeholder="1"
                            onBlur={() => {}}
                            onChange={(cmtyEventAdvocateFixedFee, e) => {
                                this.setState({ cmtyEventAdvocateFixedFee });
                            }}
                            validationOption={{
                                type: 'number',
                                numberType: 'decimal',
                                min: 0,
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                        <TextBox
                            label="Advocate User Fixed Fee"
                            tabIndex="0"
                            id="cmtyEventAdvocateFixedFee"
                            name="cmtyEventAdvocateFixedFee"
                            type="text"
                            validate={this.state.validate}
                            value={this.state.cmtyEventAdvocatePercentageFee}
                            suffix="% Per Registration"
                            placeholder="1"
                            onBlur={() => {}}
                            onChange={(cmtyEventAdvocatePercentageFee, e) => {
                                this.setState({ cmtyEventAdvocatePercentageFee });
                            }}
                            validationOption={{
                                type: 'number',
                                numberType: 'decimal',
                                min: 0,
                                check: true,
                                required: true,
                                showMsg: false,
                            }}
                        />
                        <p className="text-primary mb-0">
                            Percentage fee will be applied once registeration price is over $10. <br />
                            Ex: for the <b>10</b> registration, price per registeration is <b>$15</b>
                            <br />
                            Fee = 10 * Fixed Fee + (10 * $15) * Percentage Fee ={' '}
                            <b>
                                ${' '}
                                {10 * this.state.cmtyEventAdvocateFixedFee +
                                    (10 * 15 * this.state.cmtyEventAdvocatePercentageFee) / 100}
                            </b>
                        </p>
                    </Col>
                    <Col md={6}>
                        <h2 className="font-weight-bold mb-4">Community Event Email Service Default Fee Setting</h2>
                        <Row className="mb-5">
                            <TextBox
                                label="For broadcasting to the members of organization"
                                tabIndex="0"
                                id="cmtyMembersEmailFee"
                                name="cmtyMembersEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyMembersEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyMembersEmailFee, e) => {
                                    this.setState({ cmtyMembersEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="For broadcasting to the registered member of event"
                                tabIndex="0"
                                id="cmtyRegMembersEmailFee"
                                name="cmtyRegMembersEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyRegMembersEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyRegMembersEmailFee, e) => {
                                    this.setState({ cmtyRegMembersEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="For broadcasting to the users in special geograpic area"
                                tabIndex="0"
                                id="cmtyGeoAreaMembersEmailFee"
                                name="cmtyGeoAreaMembersEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyGeoAreaMembersEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyGeoAreaMembersEmailFee, e) => {
                                    this.setState({ cmtyGeoAreaMembersEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0.5,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="For broadcasting to all users of network"
                                tabIndex="0"
                                id="cmtyAllMembersEmailFee"
                                name="cmtyAllMembersEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyAllMembersEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyAllMembersEmailFee, e) => {
                                    this.setState({ cmtyAllMembersEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0.5,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="For broadcasting to advocates of organization"
                                tabIndex="0"
                                id="cmtyAdvocatesEmailFee"
                                name="cmtyAdvocatesEmailFee"
                                type="text"
                                className="col-12 mb-3"
                                validate={this.state.validate}
                                value={this.state.cmtyAdvocatesEmailFee}
                                placeholder="1"
                                suffix="$"
                                onBlur={() => {}}
                                onChange={(cmtyAdvocatesEmailFee, e) => {
                                    this.setState({ cmtyAdvocatesEmailFee });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    min: 0,
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="d-flex justify-content-end">
                        <Button
                            color="secondary"
                            className="btn btn-lg mb-0"
                            style={{ width: '150px' }}
                            onClick={this.update}
                        >
                            update
                        </Button>
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser, systemSettings } = user;
    return { authUser, isLoader, systemSettings };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
        putSystemSettings,
    })(ServiceFeeSettings)
);
