import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import { updateClubProfile } from 'apis';
import PageContentLayout from 'components/PageContentLayout';
import ComplexSelect from 'components/controls/ComplexSelect';

class ClubOrgTagsSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clubTags: [],
        };
    }

    componentDidMount = () => {
        this.setState({ clubTags: this.props.club?.orgTags });
    };

    save = () => {
        const params = {
            _id: this.props.club._id,
            orgTags: this.state.clubTags,
        };
        this.props.showLoader(true);
        updateClubProfile(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: `Organization Tags for ${this.props.club.displayName} has been saved`,
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        const getClubTags = () => {
            const iligibleTags = (this.state.clubTags || []).filter((tag) =>
                this.props.systemSettings.orgTags.includes(tag)
            );
            return iligibleTags.map((tag) => {
                return { value: tag, label: tag };
            });
        };

        const generateOrgTagsOption = () => {
            return this.props.systemSettings.orgTags.map((tag) => {
                return { value: tag, label: tag };
            });
        };

        return (
            <PageContentLayout title="Organization Tags">
                <Row>
                    <Col md={12}>
                        <ComplexSelect
                            placeholder="Select Tags..."
                            label="Organization Tags"
                            isClearable
                            isMulti
                            value={getClubTags()}
                            options={generateOrgTagsOption()}
                            onChange={(tags) => {
                                this.setState({ clubTags: (tags || []).map((t) => t.value) });
                            }}
                        />
                    </Col>
                    <Col md={12} className="d-flex justify-content-end mt-4">
                        <Button color="secondary" size="lg" onClick={this.save} style={{ width: '150px' }}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { authUser, systemSettings } = user;
    const { isLoader } = ui;
    return { authUser, isLoader, systemSettings };
};
export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(ClubOrgTagsSetting)
);
