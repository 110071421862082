import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import QRCode from 'qrcode.react';
import { showLoader, showMessage, handleHTTPError } from 'store/actions';
import { readMember, updateMember } from 'apis';
import TextBox from 'components/controls/TextBox';
import SelectItem from 'components/controls/SelectItem';
import { Constants } from 'utils/constants';
import PageContentLayout from 'components/PageContentLayout';
import { validateEmail } from 'utils';
import CheckBox from 'components/controls/CheckBox';

class UserView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            tempClubName: '',
            tempClubPhone: '',
            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
            gender: Constants.GENDER.NOPREFER,
            gameLevel: '',
            levelType: '',
            isRecevieNotification: true,
            isReceiveEmail: true,
            zipCode: '',
            birthYear: '',
            validate: false,
        };
    }

    componentDidMount = () => {
        const params = { _id: this.props.match.params.id };
        this.props.showLoader(true);
        readMember(params)
            .then((data) => {
                this.props.showLoader(false);
                const user = data.data;
                this.setState({
                    user,
                    tempClubName: user.tempClub?.name || '',
                    tempClubPhone: user.tempClub?.phoneNumber || '',
                    firstName: user.firstName || '',
                    lastName: user.lastName || '',
                    gender: user.gender ?? Constants.GENDER.MALE,
                    email: user.email || '',
                    phoneNumber: user.phoneNumber || '',
                    zipCode: user.zipCode || '',
                    birthYear: user.birthYear || '',
                    photoUrl: user.photoUrl,
                    isReceiveEmail: user.isReceiveEmail ?? false,
                    isRecevieNotification: user.isRecevieNotification ?? false,
                    gameLevel: user.gameLevel || '',
                    levelType: user.levelType,
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.history.push(`${this.props.match.url}`);
                this.props.handleHTTPError(error);
            });
    };

    updateProfile = () => {
        this.setState({ validate: true });
        const {
            tempClubName,
            tempClubPhone,
            firstName,
            lastName,
            email,
            phoneNumber,
            zipCode,
            birthYear,
            gender,
            levelType,
            gameLevel,
            photoUrl,
            isReceiveEmail,
            isRecevieNotification,
        } = this.state;

        if (!email || !firstName || !lastName || !validateEmail(email)) return;

        let params = {
            _id: this.state.user._id,
            tempClubName: tempClubName || undefined,
            tempClubPhone: tempClubPhone || undefined,
            firstName,
            lastName,
            gender,
            email,
            phoneNumber,
            zipCode: String(zipCode),
            birthYear: String(birthYear),
            photoUrl: photoUrl || undefined,
            isReceiveEmail,
            isRecevieNotification,
            gameLevel: gameLevel || undefined,
            levelType,
        };
        this.props.showLoader(true);
        updateMember(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({ show: true, message: { type: 'success', text: 'Member profile updated.' } });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        if (!this.state.user) return null;

        return (
            <PageContentLayout
                header={
                    <div className=" d-flex justify-content-between align-item-center">
                        <h1 className="font-weight-bold">
                            User Profile{' '}
                            <span className="h6">{this.state.user ? `ID: ${this.state.user._id}` : ''}</span>
                        </h1>
                    </div>
                }
            >
                <Row>
                    <Col md={3} className="d-flex justify-content-center align-items-center">
                        <QRCode
                            value={JSON.stringify({
                                _id: this.state.user._id,
                                fullName: this.state.fullName,
                                email: this.state.email,
                                phoneNumber: this.state.phoneNumber,
                            })}
                            level="L"
                            size={200}
                        />
                    </Col>
                    <Col md={9}>
                        <h3 className=" font-weight-bold mb-4">
                            Club Profile <span className="h6">(Optional)</span>
                        </h3>
                        <Row>
                            <TextBox
                                label={
                                    <label htmlFor="tempClubName" className="mb-0">
                                        Club Name
                                    </label>
                                }
                                id="tempClubName"
                                name="tempClubName"
                                className="col-md-4 mb-3"
                                type="text"
                                value={this.state.tempClubName}
                                placeholder="Club Name"
                                onChange={(tempClubName, e) => {
                                    this.setState({ tempClubName });
                                }}
                                validationOption={{
                                    showMsg: false,
                                    check: false,
                                    required: false,
                                }}
                            />
                            <TextBox
                                label={
                                    <label htmlFor="tempClubPhone" className="mb-0">
                                        Club Phone Number
                                    </label>
                                }
                                id="tempClubPhone"
                                name="tempClubPhone"
                                className="col-md-4 mb-3"
                                type="text"
                                value={this.state.tempClubPhone}
                                placeholder="Club Phone Number"
                                onChange={(tempClubPhone, e) => {
                                    this.setState({ tempClubPhone });
                                }}
                                validationOption={{
                                    showMsg: false,
                                    check: false,
                                    required: false,
                                }}
                            />
                        </Row>
                        <hr />
                        <Row>
                            <TextBox
                                label={
                                    <label className="mb-0 " htmlFor="firstName">
                                        First Name <span className="text-danger">*</span>
                                    </label>
                                }
                                id="fullName"
                                name="firstName"
                                className="col-md-4 mb-3"
                                type="text"
                                validate={this.state.validate}
                                value={this.state.firstName}
                                placeholder="First Name"
                                onChange={(firstName, e) => {
                                    this.setState({ firstName });
                                }}
                                validationOption={{
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label={
                                    <label className="mb-0 " htmlFor="lastName">
                                        Last Name <span className="text-danger">*</span>
                                    </label>
                                }
                                id="lastName"
                                name="lastName"
                                className="col-md-4 mb-3"
                                type="text"
                                validate={this.state.validate}
                                value={this.state.lastName}
                                placeholder="Last Name"
                                onChange={(lastName, e) => {
                                    this.setState({ lastName });
                                }}
                                validationOption={{
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label={
                                    <label className="mb-0 " htmlFor="email">
                                        Email <span className="text-danger">*</span>
                                    </label>
                                }
                                id="email"
                                name="email"
                                className="col-md-4 mb-3"
                                type="text"
                                validate={this.state.validate}
                                value={this.state.email}
                                placeholder="Email"
                                onChange={(email) => {
                                    this.setState({ email });
                                }}
                                validationOption={{
                                    name: 'Email',
                                    check: true,
                                    required: true,
                                    showMsg: false,
                                }}
                            />
                            <TextBox
                                label="Phone Number"
                                id="phoneNumber"
                                name="phoneNumber"
                                className="col-md-4 mb-3"
                                type="number"
                                value={this.state.phoneNumber}
                                placeholder="Phone Number"
                                onChange={(phoneNumber, e) => {
                                    this.setState({ phoneNumber });
                                }}
                                validationOption={{
                                    check: false,
                                    type: 'number',
                                    numberType: 'int',
                                    required: false,
                                    showMsg: false,
                                }}
                            />
                            <SelectItem
                                label="Gender"
                                id="gender"
                                size="sm"
                                className="col-md-4 mb-3"
                                value={this.state.gender}
                                optionList={[
                                    ...Object.keys(Constants.GENDER).map((value, index) => {
                                        return {
                                            id: `${index}`,
                                            name: value,
                                        };
                                    }),
                                ]}
                                onChange={(gender) => {
                                    this.setState({ gender: Number(gender.id) });
                                }}
                            />
                            <TextBox
                                label="Game Level"
                                id="gameLevel"
                                name="gameLevel"
                                className="col-md-4 mb-3"
                                type="text"
                                value={this.state.gameLevel}
                                placeholder="Game Level"
                                onChange={(gameLevel, e) => {
                                    this.setState({ gameLevel: Number(gameLevel) });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'decimal',
                                    check: false,
                                    required: false,
                                    showMsg: false,
                                }}
                            />
                            <SelectItem
                                label="Level Type"
                                id="levelType"
                                className="col-md-4 mb-3"
                                size="sm"
                                value={this.state.levelType}
                                optionList={Constants.LEVEL_TYPES}
                                onChange={(levelType) => {
                                    this.setState({ levelType: Number(levelType.id) });
                                }}
                            />
                            <TextBox
                                label="Zip Code"
                                className="col-md-4 mb-3"
                                id="zipCode"
                                name="zipCode"
                                type="text"
                                value={this.state.zipCode}
                                placeholder="96162"
                                onChange={(zipCode) => {
                                    this.setState({ zipCode: String(zipCode) });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'int',
                                    showMsg: false,
                                    check: true,
                                    required: true,
                                }}
                            />
                            <TextBox
                                label="Year of Birth"
                                className="col-md-4 mb-3"
                                id="birthYear"
                                name="birthYear"
                                type="text"
                                value={this.state.birthYear}
                                placeholder="Year of Birth"
                                onChange={(birthYear) => {
                                    this.setState({ birthYear: String(birthYear) });
                                }}
                                validationOption={{
                                    type: 'number',
                                    numberType: 'int',
                                    length: 4,
                                    showMsg: false,
                                    check: true,
                                    required: true,
                                }}
                            />
                            <CheckBox
                                label="Do you allow a member to receive email?"
                                id="isReceiveEmail"
                                name="isReceiveEmail"
                                className="col-md-6 mb-3"
                                checked={this.state.isReceiveEmail}
                                onChange={(isReceiveEmail) => {
                                    this.setState({ isReceiveEmail });
                                }}
                            />
                            <CheckBox
                                label="Do you allow a member to receive phone notificatoin?"
                                id="isRecevieNotification"
                                name="isRecevieNotification"
                                className="col-md-6 mb-3"
                                checked={this.state.isRecevieNotification}
                                onChange={(isRecevieNotification) => {
                                    this.setState({ isRecevieNotification });
                                }}
                            />
                            <Col md={12} className="d-flex justify-content-end align-items-end">
                                <Button
                                    color="secondary"
                                    size="lg"
                                    block
                                    style={{ width: '150px' }}
                                    onClick={() => this.props.history.push(`/cmtyeventusers`)}
                                >
                                    Back
                                </Button>
                                <Button
                                    color="primary"
                                    size="lg"
                                    block
                                    style={{ width: '150px' }}
                                    onClick={this.updateProfile}
                                >
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user }) => {
    const { authUser } = user;
    return { authUser };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
    })(UserView)
);
