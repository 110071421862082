import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { putSystemSettings, showLoader, showMessage, handleHTTPError } from 'store/actions';
import {
    updateSysManage,
    getDBBackupFiles,
    restoreDatabase,
    backupDatabase,
    downloadBackupFile,
    uploadBackupFile,
} from 'apis';
import SelectItem from 'components/controls/SelectItem';
import TextBox from 'components/controls/TextBox';
import swal from 'sweetalert';
import PageContentLayout from 'components/PageContentLayout';

class DBManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDBBackup: false,
            selectedRestoreDate: 0,
            selectedDownloadFile: 0,
            availableFileNames: ['No File'],
            uploadFileName: '',
            fileName: '',
            file: null,
            validate: false,
        };

        this.dbFileRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({ isDBBackup: this.props.systemSettings?.isDBBackup });
        if (this.props.systemSettings?.isDBBackup) this.getAvailableBackupFiels();
    };

    getAvailableBackupFiels = () => {
        this.props.showLoader(true);
        getDBBackupFiles({})
            .then((data) => {
                this.props.showLoader(false);
                if (data.data.length > 0) {
                    this.setState({
                        availableFileNames: data.data,
                        selectedRestoreDate: data.data.length - 1,
                        selectedDownloadFile: data.data.length - 1,
                    });
                }
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    updateDBSetting = (isDBBackup) => {
        const params = {
            _id: this.props.systemSettings._id,
            isDBBackup,
        };
        this.props.showLoader(true);
        updateSysManage(params)
            .then((data) => {
                this.props.showLoader(false);
                this.setState({
                    isDBBackup,
                });
                if (isDBBackup) {
                    this.getAvailableBackupFiels();
                }
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: 'Setting has been updated.',
                    },
                });
                this.props.putSystemSettings(data.data);
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    restoreDB = async () => {
        const fileName = this.state.availableFileNames[this.state.selectedRestoreDate];
        if (fileName === 'No File') return;
        const ok = await swal({
            title: 'Warning',
            text: `Do you want to restore ${
                this.state.availableFileNames[this.state.selectedRestoreDate]
            }? \n Are you sure?`,
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) {
            return;
        }
        const params = { fileName };
        this.props.showLoader(true);
        restoreDatabase(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: data.message,
                    },
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    backupDB = async () => {
        this.setState({
            validate: true,
        });
        if (this.state.fileName === '') {
            return;
        }
        const ok = await swal({
            title: 'Warning',
            text: `Do you want to backup DB now? \n Are you sure?`,
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) {
            return;
        }
        const params = {
            fileName: this.state.fileName,
        };
        this.props.showLoader(true);
        backupDatabase(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: {
                        type: 'success',
                        text: data.message,
                    },
                });
                const newAvFileNames = [...this.state.availableFileNames];
                newAvFileNames.push(this.state.fileName);
                this.setState({
                    availableFileNames: newAvFileNames,
                    selectedRestoreDate: newAvFileNames.length - 1,
                    selectedDownloadFile: newAvFileNames.length - 1,
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    downloadBackupDBfile = async () => {
        const params = {
            fileName: `${this.state.availableFileNames[this.state.selectedDownloadFile]}.zip`,
        };
        this.props.showLoader(true);
        downloadBackupFile(params)
            .then((data) => {
                this.props.showLoader(false);
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', params.fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    chooseFile = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.setState({
                uploadFileName: file.name,
                file: file,
            });
        }
    };

    uploadBackupDBfile = async () => {
        if (!this.state.file) return;
        if (this.state.file.size > 50000000) {
            return this.props.showMessage({
                show: true,
                message: {
                    text: 'File size is too large.',
                    type: 'warning',
                },
            });
        }
        const fileInfo = this.state.uploadFileName.split('.');
        if (fileInfo.length === 0 || fileInfo[fileInfo.length - 1] !== 'zip') {
            return this.props.showMessage({
                show: true,
                message: {
                    text: 'Invalid file format',
                    type: 'warning',
                },
            });
        }
        const ok = await swal({
            title: 'Warning',
            text: `Do you want to upload backup file \n Are you sure?`,
            dangerMode: true,
            buttons: ['No', 'Yes'],
            icon: 'warning',
        });
        if (!ok) {
            return;
        }
        const params = {
            file: this.state.file,
        };
        this.props.showLoader(true);
        uploadBackupFile(params)
            .then((data) => {
                this.props.showLoader(false);
                this.props.showMessage({
                    show: true,
                    message: {
                        text: data.message,
                        type: 'success',
                    },
                });
                const newAvFileNames = [...this.state.availableFileNames];
                if (newAvFileNames.findIndex((name) => name === this.state.uploadFileName.replace('.zip', '')) < 0) {
                    newAvFileNames.push(this.state.uploadFileName.replace('.zip', ''));
                }
                this.setState({
                    availableFileNames: newAvFileNames,
                    selectedRestoreDate: newAvFileNames.length - 1,
                    selectedDownloadFile: newAvFileNames.length - 1,
                });
            })
            .catch((error) => {
                this.props.showLoader(false);
                this.props.handleHTTPError(error);
            });
    };

    render() {
        const availableDBFileOptions = () => {
            return this.state.availableFileNames.map((date, index) => {
                return {
                    id: `${index}`,
                    name: date,
                };
            });
        };

        if (!this.props.systemSettings) return null;

        return (
            <PageContentLayout title="DB Management">
                <div className="custom-control custom-checkbox mb-5">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="isDBBackup"
                        checked={this.state.isDBBackup}
                        onChange={() => {
                            this.updateDBSetting(!this.state.isDBBackup);
                        }}
                    />
                    <label className="custom-control-label mt-1" htmlFor="isDBBackup">
                        Do you want to backup database daily?
                    </label>
                </div>
                <Row>
                    <Col md={6} sm={12}>
                        <div className="mb-4">
                            <h2 className=" font-weight-bold text-danger">Database Backup (Manual)</h2>
                            <label htmlFor="fileName">Backup current db with this file name</label>
                            <div className="d-flex">
                                <TextBox
                                    tabIndex="0"
                                    id="fileName"
                                    name="fileName"
                                    type="text"
                                    validate={this.state.validate}
                                    value={this.state.fileName}
                                    placeholder="Backup File Name"
                                    onChange={(fileName, e) => {
                                        this.setState({
                                            fileName: fileName,
                                        });
                                    }}
                                    validationOption={{
                                        name: 'Backup File Name',
                                        check: true,
                                        required: true,
                                    }}
                                />
                                <Button
                                    color="secondary"
                                    className="btn btn-secondary btn-lg ml-2"
                                    style={{ width: '150px' }}
                                    onClick={this.backupDB}
                                >
                                    Backup
                                </Button>
                            </div>
                        </div>
                        <div className="mb-4">
                            <h2 className=" font-weight-bold text-danger">Download Backup DB File</h2>
                            <label htmlFor="selectedDownloadFile">Please select backup file to download</label>
                            <div className="d-flex">
                                <SelectItem
                                    id="selectedDownloadFile"
                                    size="sm"
                                    value={this.state.selectedDownloadFile}
                                    optionList={availableDBFileOptions()}
                                    onChange={(value) => {
                                        this.setState({
                                            selectedDownloadFile: Number(value.id),
                                        });
                                    }}
                                />
                                <Button
                                    color="secondary"
                                    className="btn btn-secondary btn-lg ml-2"
                                    style={{ width: '150px' }}
                                    onClick={this.downloadBackupDBfile}
                                >
                                    Download
                                </Button>
                            </div>
                        </div>
                        <div className="mb-4">
                            <h2 className=" font-weight-bold text-danger">Upload Backup DB File</h2>
                            <label htmlFor="uploadFileName">Please choose backup file to upload</label>
                            <div className="d-flex">
                                <TextBox
                                    tabIndex="0"
                                    id="uploadFileName"
                                    name="uploadFileName"
                                    type="text"
                                    value={this.state.uploadFileName}
                                    placeholder="Upload File Name"
                                    disabled={true}
                                    validationOption={{
                                        name: 'Upload File Name',
                                        check: true,
                                        required: true,
                                        showMsg: false,
                                    }}
                                />
                                <div className="d-flex">
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-lg ml-2 mr-1"
                                        onClick={() => {
                                            this.dbFileRef.current.click();
                                        }}
                                    >
                                        ...
                                    </Button>
                                    <Button
                                        color="secondary"
                                        className="btn btn-secondary btn-lg"
                                        style={{ width: '97px' }}
                                        onClick={this.uploadBackupDBfile}
                                    >
                                        Upload
                                    </Button>
                                    <input
                                        ref={this.dbFileRef}
                                        type="file"
                                        className="form-control-file"
                                        name="file"
                                        id="file"
                                        hidden
                                        onChange={this.chooseFile}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <h2 className=" font-weight-bold text-danger">Database Restore (Manual)</h2>
                            <label htmlFor="selectedRestoreDate">Please select backup file to restore</label>
                            <div className="d-flex">
                                <SelectItem
                                    id="selectedRestoreDate"
                                    size="sm"
                                    value={this.state.selectedRestoreDate}
                                    optionList={availableDBFileOptions()}
                                    onChange={(value) => {
                                        this.setState({
                                            selectedRestoreDate: Number(value.id),
                                        });
                                    }}
                                />
                                <Button
                                    color="secondary"
                                    className="btn btn-secondary btn-lg ml-2"
                                    style={{ width: '150px' }}
                                    onClick={this.restoreDB}
                                >
                                    Restore
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </PageContentLayout>
        );
    }
}

const mapStateToProps = ({ user, ui }) => {
    const { isLoader } = ui;
    const { authUser, systemSettings } = user;
    return { authUser, isLoader, systemSettings };
};

export default withRouter(
    connect(mapStateToProps, {
        showLoader,
        showMessage,
        handleHTTPError,
        putSystemSettings,
    })(DBManage)
);
