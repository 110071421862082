import React from 'react'
import { Container } from 'reactstrap'

class PageContentLayout extends React.Component {

    render() {
        return (
            <Container fluid>
                {
                    this.props.title ? <h1 className="font-weight-bold">{this.props.title}</h1> : this.props.header
                }
                <div className="card p-5 position-relative">
                    {this.props.children}
                </div>
            </Container>
        )
    }
}

export default PageContentLayout;