import React, { Component } from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class SidebarLogo extends Component {

    constructor(props) {
        super(props)
        
        this.state = {}
    }
    

    render() {
        return (
            <div className="text-center">
                <img src="/images/logo/logo_white.png"  alt="" onClick={() => {this.props.history.push('/dashboard')}} style={{
                    height: '150px',
                    padding: '20px 0'
                }}/>
                <h2 className="text-white font-weight-bold">(Super Admin)</h2>
            </div>
        )
    }
}

export default withRouter(connect(null, {

})(SidebarLogo));
