import { combineReducers } from 'redux';
import Ui from './Ui';
import User from './User';

const reducers = combineReducers({
    ui: Ui,
    user: User,
});

export default reducers;
